import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Capacitor } from "@capacitor/core"
import { App } from "@capacitor/app";
 
function BackButtonHandler() {

  const navigate = useNavigate();
  const [exitPrompt, setExitPrompt] = useState(false); // Track if the prompt has been shown
 
  useEffect(() => {
    if (Capacitor.getPlatform() !== "android") return;
 
    const backButtonListener = App.addListener("backButton", ({ canGoBack }) => {

      if (canGoBack) {
        navigate(-1); // Navigate back if possible
        setExitPrompt(false); // Reset exit prompt state when navigating back
      } else {
        if (!exitPrompt) {
          setExitPrompt(true);
          alert("Press back again to exit"); // Show exit warning
          // Reset the exit prompt after a delay, giving time for user to press again
          setTimeout(() => {
            setExitPrompt(false);
          }, 2000); // You can adjust the timeout value (in ms)
        } else {
          App.exitApp(); // Exit the app if the user presses back again
        }
      }
    });
    return () => {
      backButtonListener.remove();
    };

  }, [navigate, exitPrompt]);

  return null;
}
 
export default BackButtonHandler;

 
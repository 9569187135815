import { BackButtonHandler } from "./components";
import { Toaster } from "react-hot-toast";
import { Index as Route } from "./routes";

function App() {
  return (
    <>
      <BackButtonHandler />
      <Toaster />
      <Route />
    </>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import { FaPowerOff } from "react-icons/fa6";
import { MdOutlineProductionQuantityLimits, MdOutlinePrivacyTip } from "react-icons/md";
import { IoMdHelpCircle, IoMdInformationCircleOutline } from "react-icons/io";
import { FaRegUserCircle, FaHome } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { IoBagAdd, IoInformationCircle, IoInformationCircleOutline, IoNotifications, IoPerson, IoSettingsOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { BsShop } from "react-icons/bs";
import { useAuthContext, useNotificationContext } from "../../context"; // Import NotificationContext
 
const Sidebar = ({ slider, setSlider, token, logoutHandler, SetNotification }) => {
    const sidebarRef = useRef(null);
    const {user}= useAuthContext();
    const { notificationCount, hasUnreadGeneralNotifications, loading } = useNotificationContext(); // Get notification data from context
    const [userType, setUserType] = useState(null); // Initialize state for user type
    const location = useLocation();
    const isOnHomePage = location.pathname === "/"; // Check if we are on the home page
    useEffect(() => {
        if (user) {
            setUserType(user.type); // Set user type from context
        }
    }, [user]);
 
    const handleLogOut = () => {
        logoutHandler(); // Handle logout action
    };
 
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setSlider(false);
            }
        };
   
        if (slider) {
            document.addEventListener("mousedown", handleClickOutside);
        }
   
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [slider, setSlider]);
 
    if (loading) {
        return <div>Loading...</div>; // Show loading indicator while fetching notifications
    }
 
    return (
        <div>
        <div
            ref={sidebarRef}
            className={`h-[100vh] w-fit fixed pt-3 right-0 ${slider ? "" : "hidden"} bg-white z-50 transition-all duration-300 py-10`}
        >
            <div className="float-end mr-5">
                <MdClose size={25} onClick={() => setSlider(false)} />
            </div>
            <ul className="py-10" onClick={() => setSlider(false)}>
                {token ? (
                    <>
                        {!isOnHomePage && (
                            <li className="hover:bg-gray-200 pe-20 py-2 block md:hidden">
                                <Link className="flex items-center gap-2 px-4" to="/">
                                    <FaHome size={25} />
                                    <span>Go to Home</span>
                                </Link>
                            </li>
                        )}
                        <li className="hover:bg-gray-200 pe-20 py-2">
                            <Link className="flex items-center gap-2 px-4" to="/profile">
                                <FaRegUserCircle size={25} />
                                <span>My Profile</span>
                            </Link>
                        </li>
                        <li className="hover:bg-gray-200 pe-20 py-2" onClick={() => SetNotification(true)}>
                            <Link className="flex items-center gap-3 px-4 py-2 rounded-md text-gray-800">
                                <IoNotifications size={25} className="text-gray-800" />
                                {notificationCount > 0 ? (
                                    <>
                                        <span className="font-semibold">Notifications</span>
                                        <span className="bg-red-500 text-white px-2 py-1 rounded-full text-xs">
                                            {notificationCount}
                                        </span>
                                    </>
                                ) : hasUnreadGeneralNotifications ? (
                                    <>
                                        <span className="font-semibold">New Announcements</span>
                                        <span className="bg-yellow-500 text-white px-2 py-1 rounded-full text-xs">
                                            New
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="font-semibold">No New Notifications</span>
                                    </>
                                )}
                            </Link>
                        </li>
                        <li className="hover:bg-gray-200 pe-20 py-2">
                            <Link className="flex items-center gap-2 px-4" to="/orders">
                                <MdOutlineProductionQuantityLimits size={25} />
                                <span>My Order</span>
                            </Link>
                        </li>
                        <li className="hover:bg-gray-200 pe-20 py-2">
                            <Link className="flex items-center gap-2 px-4" to="/offlinestore">
                                <BsShop size={25} />
                                <span>Offline Store</span>
                            </Link>
                        </li>
                        {userType === 'admin' && (
                            <li className="hover:bg-gray-200 pe-20 py-2">
                                <Link className="flex items-center gap-2 px-4" to="/admin-panel">
                                    <IoPerson size={25} />
                                    <span>Admin Panel</span>
                                </Link>
                            </li>
                        )}
                        <li className="hover:bg-gray-200 pe-20 py-2">
                            <Link to="/contactus" className="flex items-center gap-2 px-4">
                                <IoMdHelpCircle size={25} />
                                <span>Help</span>
                            </Link>
                        </li>
                        <li className="hover:bg-gray-200 pe-20 py-2" onClick={handleLogOut}>
                            <Link className="flex items-center gap-2 px-4">
                                <FaPowerOff size={25} />
                                <span>LogOut</span>
                            </Link>
                        </li>
                    </>
                ) : (
                    <>
                        <li className="hover:bg-gray-200 pe-20 py-2" onClick={() => setSlider(false)}>
                            <Link to="/login" className="flex items-center gap-2 px-4">
                                <FaRegUserCircle size={25} />
                                <span>Login</span>
                            </Link>
                        </li>
                        <li className="hover:bg-gray-200 pe-20 py-2">
                            <Link className="flex items-center gap-2 px-4" to="/offlinestore">
                                <BsShop size={25} />
                                <span>Offline Store</span>
                            </Link>
                        </li>
                        <li className="hover:bg-gray-200 pe-20 py-2">
                            <Link to="/contactus" className="flex items-center gap-2 px-4">
                                <IoMdHelpCircle size={25} />
                                <span>Help</span>
                            </Link>
                        </li>
                        {/* Show General Notifications when logged out */}
                        <li className="hover:bg-gray-200 pe-20 py-2" onClick={() => SetNotification(true)}>
                            <Link className="flex items-center gap-3 px-4 py-2 rounded-md text-gray-800">
                                <IoNotifications size={25} className="text-gray-800" />
                                {hasUnreadGeneralNotifications ? (
                                    <>
                                        <span className="font-semibold">New Announcements</span>
                                        <span className="bg-yellow-500 text-white px-2 py-1 rounded-full text-xs">
                                            New
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="font-semibold">No New Announcements</span>
                                    </>
                                )}
                            </Link>
                        </li>
                    </>
                )}
            </ul>
        </div>
        <div
        className={`fixed bottom-0 left-0 w-full bg-white z-50 flex justify-between items-center px-4 py-2 shadow-lg sm:hidden md:hidden`}
    >
        <ul className="flex justify-between w-full">
            {token ? (
                <>
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-1" to="/">
                            <FaHome size={20} />
                            <span className="text-xs">Home</span>
                        </Link>
                    </li>
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-1" to="/profile">
                            <FaRegUserCircle size={20} />
                            <span className="text-xs">Profile</span>
                        </Link>
                    </li>
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-1" to="/orders">
                            <MdOutlineProductionQuantityLimits size={20} />
                            <span className="text-xs">Orders</span>
                        </Link>
                    </li>
                    {userType === 'admin' && (
                        <li className="flex-1 text-center">
                            <Link className="flex flex-col items-center gap-1" to="/admin-panel">
                                <IoPerson size={20} />
                                <span className="text-xs">Admin</span>
                            </Link>
                        </li>
                    )}
                     {userType === 'user' && (
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-1" to="/offlinestore">
                            <BsShop size={20} />
                            <span className="text-xs">Store</span>
                        </Link>
                    </li>)}
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-0" to="/settings">
                            <IoInformationCircleOutline size={24} />
                            <span className="text-xs">Info</span>
                        </Link>
                    </li>
                    <li className="flex-1 text-center" onClick={handleLogOut}>
                        <Link className="flex flex-col items-center gap-1">
                            <FaPowerOff size={20} />
                            <span className="text-xs">Logout</span>
                        </Link>
                    </li>
                </>
            ) : (
                <>
                    {!isOnHomePage && (
                           <li className="flex-1 text-center">
                           <Link className="flex flex-col items-center gap-1" to="/">
                               <FaHome size={20} />
                               <span className="text-xs">Home</span>
                           </Link>
                          </li>
                      )}
                    <li className="flex-1 text-center">
                        <Link to="/login" className="flex flex-col items-center gap-1">
                            <FaRegUserCircle size={20} />
                            <span className="text-xs">Login</span>
                        </Link>
                    </li>
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-1" to="/offlinestore">
                            <BsShop size={20} />
                            <span className="text-xs">Store</span>
                        </Link>
                    </li>
                    <li className="flex-1 text-center">
                        <Link className="flex flex-col items-center gap-1" to="/settings">
                            <IoInformationCircleOutline size={20} />
                            <span className="text-xs">Info</span>
                        </Link>
                    </li>
                </>
            )}
        </ul>
    </div>
    </div>
    );
};
 
export default Sidebar;
 
 
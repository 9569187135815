import React, { createContext, useEffect, useReducer } from "react";
import { useAuthContext } from "..";
import { reducer } from "../../reducer";
import toast from "react-hot-toast";
import { addToCartURL, removeFromCartURL, getCartURL } from "../../api/api";

export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
  const { token } = useAuthContext();
  const stock = 10;

  const getLocalData = () => {
    const data = localStorage.getItem("Cart");
    return data ? JSON.parse(data) : [];
  };

  const initialState = {
    Cart: getLocalData(),
    total_item: "",
    total_amount: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchCartItems = async (retries = 3) => {
    if (!token) return;
  
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await fetch(getCartURL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const cartItems = await response.json();
        dispatch({ type: "SET_CART", payload: cartItems });
        return; // Exit function on success
  
      } catch (error) {
        if (error.name === "TypeError" && error.message.includes("NetworkError")) {
          //console.warn(`Network error! Retry attempt ${attempt}/${retries}...`);
          if (attempt < retries) {
            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
            continue; // Try again
          }
          toast.error("Network issue! Please check your internet and try again.");
        } else {
          toast.error("Failed to load your cart. Please try again.");
        }
        console.error("Error fetching cart:", error);
      }
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, [token]);

  const addToCart = async (item) => {
    if (!token) {
      toast.error("Please log in to add items to your cart.");
      return;
    }

    const existingItem = state.Cart.find((cartItem) => cartItem.SKU_id === item.SKU_id);
    if (existingItem) {
      toast.error("This product is already in your cart.");
    } else {
      try {
        console.log(item);
        const { _id, ...restItem } = item; // Destructure and remove `_id`
        
        const response = await fetch(addToCartURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...restItem, productcount: 1 }), // Send the rest of the item data
        });

        const result = await response.json();
        if (response.ok) {
          toast.success("Product successfully added to your cart.");
          dispatch({ type: "ADD_TO_CART", payload: { ...restItem, productcount: 1 } });
        } else {
          toast.error(result.message || "Something went wrong. Please try again.");
        }
      } catch (error) {
        toast.error("There was an issue adding the item to your cart. Please try again.");
      }
    }
  };


  const removeItem = async (item) => {
    try {
        const response = await fetch(removeFromCartURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ SKU_id: item.SKU_id }),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            toast.error(errorMessage || "Failed to remove item from cart. Please try again.");
            return;
        }

        dispatch({ type: "REMOVE_ITEM", payload: item.SKU_id });
        toast.success("Item successfully removed from your cart.");
    } catch (error) {
        toast.error("An error occurred while removing the item. Please try again.");
    }
  };

  const Increase = async (item) => {
    if (item.productcount < stock) {
      const updatedProductCount = item.productcount + 1;
      dispatch({ type: "UPDATE_CART_ITEM", payload: { ...item, productcount: updatedProductCount } });

      try {
        const response = await fetch(addToCartURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ SKU_id: item.SKU_id, productcount: updatedProductCount }),
        });
        if (!response.ok) {
          toast.error("Unable to update cart. Please try again.");
          dispatch({ type: "UPDATE_CART_ITEM", payload: item }); // Revert state
        } else {
          toast.success("Item quantity successfully updated.");
        }
      } catch (error) {
        toast.error("There was an issue updating the item quantity. Please try again.");
      }
    }
  };

  const Decrease = async (item) => {
    if (item.productcount > 1) {
      const updatedProductCount = item.productcount - 1;
      dispatch({ type: "UPDATE_CART_ITEM", payload: { ...item, productcount: updatedProductCount } });

      try {
        const response = await fetch(addToCartURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ SKU_id: item.SKU_id, productcount: updatedProductCount }),
        });
        if (!response.ok) {
          toast.error("Unable to update cart. Please try again.");
          dispatch({ type: "UPDATE_CART_ITEM", payload: item }); // Revert state
        } else {
          toast.success("Item quantity successfully decreased.");
        }
      } catch (error) {
        toast.error("There was an issue updating the item quantity. Please try again.");
      }
    } else {
      removeItem(item);
    }
  };

  const clearCart = () => {
    localStorage.setItem("Cart", JSON.stringify([]));
    dispatch({ type: "CLEAR_CART" });
  };  

  useEffect(() => {
    localStorage.setItem("Cart", JSON.stringify(state.Cart));
  }, [state.Cart]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        Increase,
        Decrease,
        addToCart,
        removeItem,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;

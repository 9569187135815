import React, { useEffect, useState, useRef, useMemo } from "react";
import { Helmet } from 'react-helmet';
import { useAuthContext, useCartContext, useWishlistContext } from "../../context";
import WishlistPage from "../../pages/Wishlistpage";
import Notification from "../../pages/Notification";
import { IoIosArrowDown } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import CategoryModal from "../Modal/CategoryModal";
import { useNotificationContext } from "../../context";  // Import the hook
import { BiDotsVerticalRounded } from "react-icons/bi"; // Added missing import
import Sidebar from "./Sidebar";
import SearchBar from "../SearchBar/SearchBar"; // Added missing import
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import PolicyModal from "../Modal/PolicyModal";
 
const Navbar = () => {
  const { Cart } = useCartContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { token, logoutHandler } = useAuthContext();
  const { Wishlist } = useWishlistContext();
  const { notificationCount,hasUnreadGeneralNotifications } = useNotificationContext(); // Use notification context
  const [slider, setSlider] = useState(false);  // Sidebar visibility controlled by this state
  const [wishlist, setWishlist] = useState(false);
  const [notification, SetNotification] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
 
  const [isPolicyModalOpen, setPolicyModalOpen] = useState(false);
 
  const openPolicyModal = () => {
    setPolicyModalOpen(true);
  };
 
  const closePolicyModal = () => {
    setPolicyModalOpen(false);
  };
 
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = () => {
      try {
        const storedProducts = JSON.parse(localStorage.getItem("products"));
        if (Array.isArray(storedProducts) && storedProducts.length > 0) {
          setProducts(storedProducts);
          return true; // Stop retrying
        }
      } catch (error) {
        console.error("Error parsing products:", error);
      }
      return false; // Keep retrying
    };
  
    if (!fetchProducts()) {
      const interval = setInterval(() => {
        if (fetchProducts()) {
          clearInterval(interval); // Stop checking once data is found
        }
      }, 500);
  
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, []);
  
  
  const [groupedProducts, setGroupedProducts] = useState({});
  const dropdownRef = useRef(null);  // Define dropdownRef

  useEffect(() => {
    const categorizedProducts = products.reduce((acc, product) => {
      if (!product.category) return acc;
      if (!acc[product.category]) acc[product.category] = [];
      acc[product.category].push(product);
      return acc;
    }, {});
    setGroupedProducts(categorizedProducts);
  }, [products]);
  
  const scrollToTop = () => window.scrollTo(0, 0);
 
  // Handle Category Select and Dropdown
  const handleProductSelect = (productId) => {
    navigate(`/product/${productId}`);
    scrollToTop();
    setActiveDropdown(null);
    setIsModalOpen(false);
  };
 
  const handleMouseEnter = (category) => {
    if (window.innerWidth >= 768) setActiveDropdown(category);
  };
 
  const handleMouseLeave = () => {
    if (window.innerWidth >= 768) setActiveDropdown(null);
  };
 
  const handleCategoryClick = (category) => {
    if (window.innerWidth < 768) {
      setSelectedCategory(category);
      setIsModalOpen(true);
    } else {
      setActiveDropdown((prev) => (prev === category ? null : category));
    }
  };
 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && window.innerWidth < 768) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
 
  // Sidebar only opens on hamburger click
  const handleHamburgerClick = () => {
    setSlider(true);
  };
 
  return (
    <>
    <Helmet>
        <meta property="og:site_name" content="DigiUncle Creations" />
      </Helmet>
      <Sidebar
        slider={slider}
        setSlider={setSlider}
        token={token}
        logoutHandler={logoutHandler}
        SetNotification={SetNotification}
      />
      <WishlistPage wishlist={wishlist} setWishlist={setWishlist} />
      <Notification SetNotification={SetNotification} notification={notification} />
 
      {/* Main Navbar */}
      <nav className="w-screen sm:flex sm:flex-row xs:flex-col md:flex-row lg:flex-row xl:flex-row sm:px-4 items-center justify-center sm:justify-between py-2 z-30 bg-white sticky top-0 shadow-md">
        {/* Structured Data for Footer */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "DigiUncle",
                        "url": "https://digiuncle.co.in",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Achievers Center Point, Level-1, Sector 49, Kalindi Hill",
                            "addressLocality": "Faridabad",
                            "addressRegion": "Haryana",
                            "postalCode": "121001",
                            "addressCountry": "IN"
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+91 9717298198",
                            "contactType": "Customer Service"
                        },
                        "sameAs": [
                            "https://www.instagram.com/digi_uncle/",
                            "https://www.youtube.com/@DigiUncleCreation"
                        ]
                    })}
                </script>
        <div className="flex justify-between mx-4 items-center z-50 sticky top-0 relative">
        <Link to="/">
  <img
    src="/image/logo.jpeg"
    alt="Logo"
    className="
    max-w-[150px] max-h-[80px]
    sm:max-w-[110px] sm:max-h-[68px]
    md:max-w-[120px] md:max-h-[70px]
    lg:max-w-[135px] lg:max-h-[75px]
    xl:max-w-[160px] xl:max-h-[80px]
    w-auto h-auto object-contain"
  />
</Link>
          {/* Sidebar opener for mobile positioned at top-right */}
          <div className="flex items-center gap-8 justify-end lg py-1 z-100 ml-auto">
          <div className=" sm:hidden md:hidden lg:hidden xl:hidden flex-1 text-center" onClick={() => SetNotification(true)}>
                        <Link className="flex flex-col items-center gap-1">
                            <IoNotifications size={20} />
                            {notificationCount > 0 ? (
                                <span className="text-xs bg-red-500 text-white px-2 rounded-full">
                                    {notificationCount}
                                </span>
                            ) : hasUnreadGeneralNotifications ? (
                                <span className="text-xs bg-yellow-500 text-white px-2 rounded-full">
                                    New
                                </span>
                            ) : (
                                <span className="text-xs">No Notifications</span>
                            )}
                        </Link>
                    </div>
                    </div>
        </div>
        {/* Search Bar */}
  <div className="w-full px-2 xxl:ml-72">
    <div className="flex sm:hidden justify-center py-2">
      <SearchBar />
    </div>
    <div className="hidden sm:flex justify-center">
      <SearchBar />
    </div>
  </div>
        <div className="flex flex-wrap sm:flex-nowrap items-center justify-center mx-4 ">
  <ul className="flex sm:flex-nowrap gap-5 sm:gap-1 lg:gap-5 md:gap-[0.5rem] items-center justify-center w-full sm:w-auto overflow-x-auto py-2">
            <li onClick={() => navigate("/productlist")}>
              <Link className="flex gap-0 flex-col items-center relative">
                <img src="/image/product.png" alt="Product" className="m-0 p-0" style={{ height: "30px", width: "30px" }} />
                <span className="md:flex p-0 m-0 sm:text-sm text-xs">
                  <b>Products</b>
                </span>
              </Link>
            </li>
            <li onClick={() => navigate("/Cart")}>
              <Link className="flex gap-0 flex-col items-center relative">
                <img src="/image/shopping-cart.png" alt="Shopping Cart" className="m-0 p-0 text-3xl" style={{ height: "30px", width: "30px" }} />
                <span className="md:flex p-0 m-0 sm:text-sm text-xs">
                  <b>Cart</b>
                  {Cart?.length > 0 && (
                    <span className="bg-red-500 text-white font-bold px-[6px] rounded-full text-sm absolute top-[-15%] right-[-8px]">
                      {Cart?.length}
                    </span>
                  )}
                </span>
              </Link>
            </li>
            <li>
              <Link className="flex flex-col items-center relative" style={{ color: "red" }} onClick={() => setWishlist(true)}>
              <img src="/image/like.png" alt="Wishlist" className="m-0 p-0 text-3xl" style={{ height: "30px", width: "30px" }} />
                <span className="md:flex p-0 m-0 sm:text-sm text-xs" style={{ color: "black" }}>
                  <b>Wishlist</b>
                  {Wishlist?.length > 0 && (
                    <span className="bg-red-500 text-white font-bold px-[6px] rounded-full text-sm absolute top-[-15%] right-[1px]">
                      {Wishlist?.length}
                    </span>
                  )}
                </span>
              </Link>
            </li>
            <li onClick={() => navigate("/contactus")}>
  <Link className="flex flex-col items-center relative">
    <img src="/image/operator.png" alt="Contact Us" className="m-0 p-0" style={{ height: "30px", width: "30px" }} />
    <span className="md:flex p-0 m-0 sm:text-sm text-xs whitespace-nowrap">
      <b>Contact Us</b>
    </span>
  </Link>
</li>
<li onClick={openPolicyModal}>
            <Link className="flex flex-col items-center relative">
              <img
                src="/image/policy.png"
                alt="Policies"
                className="m-0 p-0"
                style={{ height: "30px", width: "30px" }}
              />
              <span className="md:flex p-0 m-0 sm:text-sm text-xs">
                <b>Policies</b>
              </span>
            </Link>
          </li>
           {/* Sidebar opener for desktop */}
           <li className="hidden sm:flex relative cursor-pointer mr-2" onClick={handleHamburgerClick}>
              <BiDotsVerticalRounded size={30} />
              {notificationCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white font-bold w-4 h-4 rounded-full flex items-center justify-center text-xs">!</span>
              )}
            </li>
          </ul>
        </div>
      </nav>
      <hr />
      {/* Category Menu */}
      {location.pathname === "/product/:id" ? null : (
         <ul className="flex flex-wrap items-center justify-center py-0 md:py-2 bg-gradient-to-r from-blue-50 to-blue-100">
        <div className="py-0 w-full md:flex justify-center bg-gradient-to-r from-blue-50 to-blue-100 ">
          <nav className="max-w-7xl w-full">
            <ul className="flex items-center justify-evenly font-bold font-roboto text-gray-800" ref={dropdownRef}>
              {Object.keys(groupedProducts).map((category) => (
                <li
                  className="relative group"
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  onMouseEnter={() => handleMouseEnter(category)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className="flex flex-col sm:flex-row items-center cursor-pointer px-3 py-2 sm:px-0 rounded-md transition-colors duration-500 text-sm md:text-lg ">
                    <span>{category}</span>
                    <IoIosArrowDown className="mt-1 sm:ml-1 sm:mt-0" />
                  </span>
                  <ul className={`dropdown-content ${activeDropdown === category ? "show" : ""}`}>
                    {groupedProducts[category]?.map((product) => (
                      <li
                        key={product._id}
                        onClick={() => handleProductSelect(product.SKU_id)}
                        className="px-4 py-1 hover:bg-blue-100 cursor-pointer transition-colors duration-300"
                      >
                        {product.name}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </nav>
          <PolicyModal isOpen={isPolicyModalOpen} onClose={closePolicyModal} />
        </div>
        </ul>
      )}
 
      {/* Render Category Modal for mobile view */}
      <CategoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        items={groupedProducts[selectedCategory] || []}
        onItemClick={handleProductSelect}
      />
    </>
  );
};
 
export default Navbar;
 
import React, { useState, useEffect } from "react";
import axios from "axios";
import { add_productURL, imageURL } from "../../api/api";
import { useNavigate, useLocation } from 'react-router-dom';
import toast from "react-hot-toast";
import { useAuthContext } from '../../context';
 
const AddProductForm = () => {
  const { user } = useAuthContext(); 
  const initialFormData = {
    name: "",
    brand: "",
    IN_stock: "",
    listing: "",
    product_color: "",
    MRP_price: "",
    selling_price: "",
    category: "",
    material: "",
    weight: "",
    Height: "",
    Width: "",
    Length: "",
    Depth: "",
    description: "",
    SKU_id: "",
    HSN_code: "",
    tax: "",
    Local_charge: "",
    Zonal_charge: "",
    customMaterial: "",
    National_charges: "",
    image: [], // For handling image files
    Links: [],
  };
 
  const location = useLocation();
  const { token } = location.state || {};

  const [loading, setLoading] = useState(false); // Initialize loading as false
  const navigate = useNavigate();
  const [userType, setUserType] = useState(null);
    useEffect(() => {
          if (user) {
              setUserType(user.type); // Set user type from context
          }
      }, [user]);
 
  const [categories, setCategories] = useState([]);
  const [isOtherCategory, setIsOtherCategory] = useState(false); // State to track if "Other" is selected
 
  useEffect(() => {
    // Fetch categories from localStorage when the component is mounted
    const storedProducts = JSON.parse(localStorage.getItem("products")) || [];
    const uniqueCategories = Array.from(new Set(storedProducts.map((product) => product.category)));
    setCategories(uniqueCategories);
  }, []);
 
  const [formData, setFormData] = useState(initialFormData);
 
  const capitalizeFirstLetter = (str) => {
    const cleanString = str.replace(/_/g, " "); // Replace underscores with spaces
    return cleanString.charAt(0).toUpperCase() + cleanString.slice(1); // Capitalize first letter
  };
 
  const handleFieldChange = (e, field) => {
    const { value } = e.target;
    const unit = formData[`${field}_unit`] || ""; // Get the selected unit
    const valueWithoutUnit = value.replace(` ${unit}`, "").trim(); // Remove any existing unit from the value
    setFormData((prevData) => ({
      ...prevData,
      [field]: unit ? `${valueWithoutUnit} ${unit}` : value, // Append the unit if selected
    }));
  };
 
  const handleUnitChange = (e, field) => {
    const { value: unit } = e.target;
    setFormData((prevData) => {
      const currentValue = prevData[field] || "";
      const valueWithoutOldUnit = currentValue.replace(` ${prevData[`${field}_unit`] || ""}`, "").trim(); // Remove old unit
      return {
        ...prevData,
        [`${field}_unit`]: unit, // Update the selected unit
        [field]: valueWithoutOldUnit ? `${valueWithoutOldUnit} ${unit}` : "", // Append the new unit to the value
      };
    });
  };
 
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      setIsOtherCategory(value === "Other");
    }
    setFormData({
      ...formData,
      [name]: name === "Links" ? value.split(",").map((link) => link.trim()) : value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      imageFiles: Array.from(e.target.files),
    });
  };

  const handleImageLinkChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.value.split(",").map((link) => link.trim()), // Store multiple image links
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const { image, imageFiles, otherCategory, ...productData } = formData;
  
      if (isOtherCategory) {
        productData.category = otherCategory;
      }
  
      productData.image = image; // Keep image URLs
  
      // Check if imageFiles exist
      if (imageFiles?.length > 0) {
        const imageFormData = new FormData();
        formData.imageFiles.forEach((file) => imageFormData.append("files", file));
        imageFormData.append("SKU_id", productData.SKU_id);
  
        try {
          const imageUploadResponse = await axios.post(imageURL, imageFormData, {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
          });
  
          if (imageUploadResponse.status === 200) {
            console.log("Images uploaded successfully");
          } else {
            console.error("Image upload failed:", imageUploadResponse.data.message);
            toast.error(imageUploadResponse.data.message || "Image upload failed.");
          }
        } catch (error) {
          console.error("Error uploading images:", error);
          toast.error(error.response?.data?.message || "Error uploading images.");
        }
      }
  
      // API Call for Adding Product
      const addProductResponse = await axios.post(add_productURL, productData, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      });
  
      if (addProductResponse.status === 201) {
        console.log("Product added successfully:", addProductResponse.data);
        toast.success("Product added successfully!");
        setFormData(initialFormData);
        setIsOtherCategory(false);
      } else {
        console.error("Failed to add product. Status:", addProductResponse.status);
        toast.error(addProductResponse.data?.message || "Failed to add the product. Please try again.");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error(error.response?.data?.message || "An error occurred while adding the product.");
    } finally {
      setLoading(false);
    }
  };
  
  if (!user || user.type !== "admin") {
    toast.error("Unauthorized access");
    navigate("/");
    return null;
  }
 
  if (user.type === "admin") {
  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-50 justify-center items-start px-6 py-8 gap-6 md:mb-0 mb-20">
    <div className="bg-white px-8 py-4 rounded-lg shadow-lg w-full max-w-2xl">
    <h1 className="text-3xl font-bold text-center mb-4">Add Product</h1>
      <form onSubmit={handleSubmit} className="space-y-8">
       {/* Product Details Section */}
       <div className="bg-blue-100 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Product Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {["name", "listing", "product_color", "MRP_price", "selling_price", "material"].map(
            (field, index) => (
              <div key={index}>
                <label className="block text-sm font-medium text-gray-700">{capitalizeFirstLetter(field)}</label>
                <input
                  type="text"
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  placeholder={`Enter ${capitalizeFirstLetter(field)}`}
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            )
          )}

        <div>
<label className="block text-sm font-medium text-gray-700">Brand</label>
<div className="mt-1 flex space-x-4">
  <label className="inline-flex items-center">
    <input
      type="radio"
      name="brand"
      value="DigiUncle"
      checked={formData.brand === "DigiUncle"}
      onChange={handleChange}
      className="form-radio text-blue-600"
    />
    <span className="ml-2">DigiUncle</span>
  </label>
  <label className="inline-flex items-center">
    <input
      type="radio"
      name="brand"
      value="Amazon Associate Products"
      checked={formData.brand === "Amazon Associate Products"}
      onChange={handleChange}
      className="form-radio text-blue-600"
    />
    <span className="ml-2">Amazon Associate</span>
  </label>
</div>
</div>

<div>
<label className="block text-sm font-medium text-gray-700">Stock Status</label>
<div className="mt-1 flex space-x-4">
  <label className="inline-flex items-center">
    <input
      type="radio"
      name="IN_stock"
      value="in-stock"
      checked={formData.IN_stock === "in-stock"}
      onChange={handleChange}
      className="form-radio text-blue-600"
    />
    <span className="ml-2">In Stock</span>
  </label>
  <label className="inline-flex items-center">
    <input
      type="radio"
      name="IN_stock"
      value="out-stock"
      checked={formData.IN_stock === "out-stock"}
      onChange={handleChange}
      className="form-radio text-blue-600"
    />
    <span className="ml-2">Out of Stock</span>
  </label>
</div>
</div>
             {/* Category Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Category</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Category</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {capitalizeFirstLetter(category)}
                </option>
              ))}
              <option value="Other">Other</option> {/* Add "Other" option */}
            </select>
            </div>
            {/* If "Other" is selected, display the input field */}
            {isOtherCategory && (
              <div >
                <label className="block text-sm font-medium text-gray-700">Other Category</label>
                <input
                  type="text"
                  name="otherCategory"
                  value={formData.otherCategory}
                  onChange={handleChange}
                  placeholder="Enter custom category"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            )}
       
          {/* Textarea for description */}
          {["description"].map((field, index) => (
            <div key={index} className="md:col-span-2"> {/* Ensures textarea spans two columns in larger screens */}
              <label className="block text-sm font-medium text-gray-700">{capitalizeFirstLetter(field)}</label>
              <textarea
                name={field}
                value={formData[field]}
                onChange={handleChange}
                placeholder={`Enter ${capitalizeFirstLetter(field)}`}
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                rows={3} // Optional: Set the number of rows for the textarea
              />
            </div>
          ))}
        </div>
      </div>
 
 
          {/* Dimensions Section */}
          <div className="bg-blue-100 p-6 rounded-lg shadow-md">
  <h3 className="text-xl font-semibold text-gray-700 mb-4">Dimensions & Specifications</h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    {["Height", "Width", "Length", "Depth", "Weight"].map((field, index) => (
      <div key={index} className="flex items-center">
        <div className="flex-grow">
          <label className="block text-sm font-medium text-gray-700">{capitalizeFirstLetter(field)}</label>
          <input
            type="text"
            name={field}
            value={formData[field]}
            onChange={(e) => handleFieldChange(e, field)}
            placeholder={`Enter ${capitalizeFirstLetter(field)}`}
            className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
          />
          {field !== "Material" && (
          <select
            name={`${field}_unit`}
            value={formData[`${field}_unit`] || ""}
            onChange={(e) => handleUnitChange(e, field)}
            className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select Unit</option>
            <option value="cm">cm</option>
            <option value="inch">inch</option>
            <option value="feet">feet</option>
            <option value="meter">meter</option>
            <option value="kg">kg</option>
            <option value="gm">gm</option>
            <option value="litre">litre</option>
            <option value="ml">ml</option>
            {/* Add more units as needed */}
          </select>
          )}
        </div>
      </div>
    ))}
  </div>
</div>
 
 
 
        {/* Pricing & Tax Section */}
        <div className="bg-blue-100 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Pricing & Charges</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {["SKU_id", "HSN_code", "tax", "Local_charge", "Zonal_charge", "National_charges"].map((field, index) => (
              <div key={index}>
                <label className="block text-sm font-medium text-gray-700">{capitalizeFirstLetter(field)}</label>
                <input
                  type="text"
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  placeholder={`Enter ${capitalizeFirstLetter(field)}`}
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            ))}
          </div>
        </div>
 
         {/* Image Upload & Links */}
         <div className="bg-blue-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Media & Links</h3>
            <div className="grid grid-cols-1 gap-6">
              {/* Image Upload via Link */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Image URLs (comma separated)</label>
                <input
                  type="text"
                  name="image"
                  value={formData.image.join(", ")}
                  onChange={handleImageLinkChange}
                  placeholder="Enter image URLs separated by commas"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* File Upload Option */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Upload Images</label>
                <input
                  type="file"
                  name="imageFiles"
                  onChange={handleFileChange}
                  multiple
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Existing Links Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Product Links</label>
                <input
                  type="text"
                  name="Links"
                  value={formData.Links.join(", ")}
                  onChange={handleChange}
                  placeholder="Enter product links (comma separated)"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
 
        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-600 text-white text-lg py-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
        >
         {loading?'Loading':'Add Product'}
        </button>
      </form>
      </div>
      </div>
  );
}
else {
  toast.error("Unauthorized access");
  navigate('/');
  return null;
}
};
 
export default AddProductForm;
 
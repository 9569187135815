import React, { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context';
import OTPInput, { ResendOTP } from 'otp-input-react';

const VerifyOtp = () => {
  const { OTPset, verifyOtp } = useAuthContext();
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const {user}  = useAuthContext();
  const [loading, setLoading]= useState(false);
  const [isDisabled, setIsDisabled] = useState(true); // Disable resend button initially
  const [timer, setTimer] = useState(30); // Timer for resend OTP


  // Retrieve email/mobile from location state or localStorage
  const email = location?.state?.email || user?.email || '';
  const mobile = location?.state?.mobile ;
  const purpose = location?.state?.purpose; // emailVerification or mobileVerification
  const fromProfile = location?.state?.fromProfile;
  const fromcart = location?.state?.fromcart;
  const pf = user || {};
console.log(email,mobile,purpose);
  useEffect(() => {
    if (!email && !mobile) {
      toast.error('Email or Mobile is required');
      navigate('/login'); // Navigate back to login page if missing email/mobile
    }
  }, [email, mobile, navigate]);

  useEffect(() => {
    if (isDisabled) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsDisabled(false); // Enable the button when timer ends
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval); // Cleanup timer on unmount
    }
  }, [isDisabled]);

  // Resend OTP based on the email/mobile present
  const handleGetOTP = async () => {
    try {
      const result = await OTPset({ email: purpose === 'emailVerification' || purpose === 'passwordChange' ? email : null, mobile: purpose === 'mobileVerification' || purpose === 'passwordChange' ? mobile : null });
      if (result) {
        toast.success(`OTP sent to your ${purpose === 'emailVerification' || purpose === 'passwordChange' ? 'email' : 'mobile'}`);
        setTimer(30); // Reset the timer
        setIsDisabled(true); // Disable the button again
      }
    } catch (error) {
      toast.error('An error occurred while sending OTP');
    }
  };

  // Handle OTP verification
  const handleVerifyOTP = async () => {
    setLoading(true)
    if (!otp) {
      toast.error('OTP is required');
      setLoading(false);
      return;
    }

    try {
      const result = await verifyOtp({ otp, email, mobile, purpose }); // Use the correct email/mobile
      if (result) {
        // Update user's verification status
        if (purpose === 'emailVerification'||purpose ==='passwordChange') {
          pf.isEmailVerified = true;
        } else if (purpose === 'mobileVerification') {
          pf.isMobileOtpVerified = true;
        }

        // Store updated user data
        localStorage.setItem('userData', JSON.stringify(pf));
        toast.success('Verification successful');

        // Redirect based on verification context
        if (fromProfile) {
          navigate('/profile');
        } else if (fromcart) {
          navigate('/checkout');
        } else {
          navigate('/reset-password', { state: { email: email || mobile , fromProfile:true } });
        }
      }
    } catch (error) {
      toast.error('Failed to verify OTP');
    }
  };

  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <div className="bg-blue-50 shadow-lg rounded-lg p-6 w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
        <h3 className="text-3xl text-black font-semibold mb-6 text-center">Verify Your OTP</h3>
        <div className="text-lg text-gray-700 mb-4 text-center">
          Enter the OTP sent to your {purpose === 'emailVerification' ? 'email' : 'mobile'}
        </div>

        <div className="flex justify-center mb-4">
          <OTPInput
            className="otpnum"
            value={otp}
            OTPLength={6}
            onChange={(otp) => setOtp(otp)}
          />
        </div>

        <div className="flex justify-center mb-6">
          <ResendOTP
            maxTime={30} // User must wait 30 seconds before resending
            onResendClick={handleGetOTP}
            renderButton={({ disabled, onClick }) => (
              <button
                onClick={onClick}
                disabled={isDisabled} // Use custom state for enabling/disabling
                className={`text-blue-500 border border-blue-700 rounded px-6 py-3 font-semibold ${
                  isDisabled
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer hover:bg-blue-500 hover:text-white transition-all'
                }`}
              >
                Resend OTP {isDisabled && `(${timer}s)`}
              </button>
            )}
            renderTime={() => null} // Render nothing for the timer
          />
        </div>

        <div className="text-center">
          <button
            className="bg-blue-500 text-white rounded px-8 py-3 text-xl font-semibold w-full hover:bg-blue-600 transition-all"
            onClick={handleVerifyOTP}
          >
           {loading ?'Loading....': 'Verify OTP'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;

import React from 'react';
import { Link } from 'react-router-dom';
 
const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
 
const Footer = () => {
    return (
        <footer className="bg-[#172337] text-blue-500 dark:bg-black sm:block md:block lg:block hidden">
            <div className="container mx-auto px-6 pb-5">
                {/* Text at the top */}
                <div className="mt-1 text-center text-gray-300 dark:text-gray-400 text-base leading-relaxed mb-3">
                DigiUncle Creations offers handcrafted home decor and a curated range of Amazon Associate products, from home essentials to fashion and electronics.
         </div>
                {/* Structured Data for Footer */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "DigiUncle",
                        "url": "https://digiuncle.co.in",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Achievers Center Point, Level-1, Sector 49, Kalindi Hill",
                            "addressLocality": "Faridabad",
                            "addressRegion": "Haryana",
                            "postalCode": "121001",
                            "addressCountry": "IN"
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+91 9717298198",
                            "contactType": "Customer Service"
                        },
                        "sameAs": [
                            "https://www.instagram.com/digi_uncle/",
                            "https://www.youtube.com/@DigiUncleCreation"
                        ]
                    })}
                </script>
 
                <div className="h-full grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                    <div>
                        <p className="font-semibold text-gray-300 dark:text-white">ABOUT</p>
                        <div className="mt-5 flex flex-col text-blue-400 items-start space-y-2">
                            <Link to="/aboutus" onClick={scrollToTop} title="Learn more about DigiUncle" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">About Us</Link>
                            <Link to="/" onClick={scrollToTop} title="Visit DigiUncle's homepage" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Home</Link>
                            <Link to="/offlinestore" onClick={scrollToTop} title="Find our offline stores" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Offline Store</Link>
                        </div>
                    </div>
 
                    <div>
                        <p className="font-semibold text-gray-300 dark:text-white">HELP</p>
                        <div className="mt-5 flex flex-col text-blue-400 items-start space-y-2">
                        <Link to="/contactus" onClick={scrollToTop} title="Contact details" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Contact Us</Link>
                        <div className="mt-5 flex flex-col text-blue-400 items-start space-y-2">
                            <p className="text-gray-300 dark:text-gray-300">0129-4067217/+91 9717298198</p>
                        </div>
                        </div>
                    </div>
 
                    <div>
                        <p className="font-semibold text-gray-300 dark:text-white">COMPANY POLICY</p>
                        <div className="mt-5 flex flex-col text-blue-400 items-start space-y-2">
                        <Link to="/shipping" onClick={scrollToTop} title="Shipping details" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Shipping</Link>
                            <Link to="/cancellation" onClick={scrollToTop} title="Cancellation and return policy" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Cancellation & Return</Link>
                            <Link to="/terms" onClick={scrollToTop} title="Read terms of use" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Terms of Use</Link>
                            <Link to="/privacypolicy" onClick={scrollToTop} title="Privacy policy details" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Privacy Policy</Link>
                        </div>
                    </div>
 
                    <div>
                        <p className="font-semibold text-gray-300 dark:text-white">SOCIAL</p>
                        <div className="mt-5 flex flex-col text-blue-400 items-start space-y-2">
                            <Link to="https://www.instagram.com/digi_uncle_creations/" rel="noopener noreferrer" target="_blank" title="Visit our Instagram profile" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Instagram</Link>
                            <Link to="https://www.youtube.com/@DigiUncleCreation" rel="noopener noreferrer" target="_blank" title="Visit our YouTube channel" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">YouTube</Link>
                            <Link to="https://www.google.com/maps/place/DigiUncle+Creations/@28.3959162,77.2667709,17z/data=!4m18!1m9!3m8!1s0x390cdf2ccc7db611:0x313089fc3e02db55!2sDigiUncle+Creations!8m2!3d28.3959162!4d77.2667709!9m1!1b1!16s%2Fg%2F11wx9rvqf9!3m7!1s0x390cdf2ccc7db611:0x313089fc3e02db55!8m2!3d28.3959162!4d77.2667709!9m1!1b1!16s%2Fg%2F11wx9rvqf9?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" rel="noopener noreferrer" target="_blank" title="Check out our reviews" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Google Reviews</Link>
                            <Link to="https://g.page/r/CVXbAj78iTAxECA/review" rel="noopener noreferrer" target="_blank" title="Leave us a review" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">Leave A Review</Link>
                        </div>
                    </div>
 
                    <div>
                        <p className="font-semibold text-gray-300 dark:text-white">ADDRESS</p>
                        <div className="mt-5 flex flex-col text-blue-400 items-start space-y-2">
                            <Link to="https://www.google.com/maps/place/DigiUncle+Creations/data=!4m2!3m1!1s0x0:0x313089fc3e02db55?sa=X&ved=1t:2428&ictx=111" rel="noopener noreferrer" target="_blank" title="Find us on Google Maps" className="transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400">
                                Digiuncle Creations, Achievers Center Point, Level-1, Sector 49, Kalindi Hill, Faridabad, Haryana 121001
                            </Link>
                        </div>
                    </div>
                </div>
 
                <hr className="my-2 border-gray-200 dark:border-gray-700 md:my-4" />
               
                <div className="flex flex-col items-center justify-between sm:flex-row">
                    <div className="text-2xl font-bold text-gray-800 transition-colors duration-300 hover:text-gray-700 dark:text-white dark:hover:text-gray-300">
                        <span className='text-[#41d8f8]'>Digi</span><span className='text-[#f24572]'>Uncle</span>
                    </div>
                    <p className="mt-2 text-sm text-white dark:text-white sm:mt-0">© Copyright 2023-{new Date().getFullYear()}. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
}
 
export default Footer;
 
 
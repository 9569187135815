// import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
// import { useNavigate } from 'react-router-dom';
// import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
// import { useAuthContext } from '../context';
// import toast from 'react-hot-toast';
// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'; // For web
// import { Capacitor } from '@capacitor/core';
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

// const ForgotPasswordModal = ({ isVisible, onClose }) => {
//     const { OTPset } = useAuthContext();
//     const [email, setEmail] = useState('');
//     const [mobile, setMobile] = useState('');
//     const [useMobile, setUseMobile] = useState(false);
//     const navigate = useNavigate();

//     const handleSendOTP = async () => {
//         try {
//             const result = useMobile
//                 ? await OTPset({ mobile })
//                 : await OTPset({ email });

//             if (result) {
//                 toast.success(result.message || "OTP sent successfully");
//                 onClose();
//                 navigate('/forgot-otp', {
//                     state: {
//                         Forgotmobile: useMobile ? mobile : null,
//                         Forgotemail: useMobile ? null : email,
//                         purpose: 'passwordChange',
//                     },
//                 });
//             }
//         } catch (error) {
//             toast.error("Failed to send OTP");
//         }
//     };

//     if (!isVisible) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//             <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
//                 <h2 className="text-xl font-semibold mb-4">Forgot Password</h2>

//                 {useMobile ? (
//                     <input
//                         type="text"
//                         className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none mb-4"
//                         placeholder="Enter your registered mobile number"
//                         value={mobile}
//                         onChange={(e) => setMobile(e.target.value)}
//                     />
//                 ) : (
//                     <input
//                         type="email"
//                         className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none mb-4"
//                         placeholder="Enter your registered email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                     />
//                 )}

//                 <div className="flex justify-end space-x-3">
//                     <button className="bg-gray-400 text-white px-4 py-2 rounded" onClick={onClose}>
//                         Cancel
//                     </button>
//                     <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleSendOTP}>
//                         Send OTP
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const Login = () => {
//     const { loginHandler, loading, onGoogleSuccess } = useAuthContext();
//     const navigate = useNavigate();
//     const [showPassword, setShowPassword] = useState(false);
//     const [showModal, setShowModal] = useState(false);
//     const [loginCredential, setLoginCredentials] = useState({
//         email: "",
//         password: "",
//     });

//     const [isAndroid, setIsAndroid] = useState(false);

//     const ClientId = "673274437090-f4fd0rh2t4b6evqi860c0bqhe0h2pqb2.apps.googleusercontent.com";
//    // const androidClientId = '673274437090-0cs7k07v5dj4k00otr2m4erce3cq2rj2.apps.googleusercontent.com';

//     useEffect(() => {
//         const platform = Capacitor.getPlatform();
//         if (platform === "android") {
//             setIsAndroid(platform === 'android'); // Set isAndroid based on platform
//             // Initialize GoogleAuth with the Android Client ID
//             GoogleAuth.initialize({
//                 clientId: ClientId,
//                 scopes: ["profile", "email"], // Add any other scopes your app requires
//                 grantOfflineAccess: true,
//             });
//             console.info("GoogleAuth initialized successfully on Android.");
//         }
//     }, []);
    
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const result = await loginHandler(loginCredential);
//         if (result.success) {
//             toast.success(result.message);
//             setTimeout(() => {
//                 window.location.href = "/";
//             }, 1000);
//         } else {
//             console.error("Login failed:", result.message);
//         }
//     };

//     const handleGoogleSignInAndroid = async () => {
//         try {
//             console.debug("Attempting Google Sign-In on Android...");
//             const googleUser = await GoogleAuth.signIn();
//             console.debug("Google User Info:", googleUser);
    
//             if (!googleUser || !googleUser.authentication?.idToken) {
//                 console.error("Google Sign-In failed: ID token missing.");
//                 throw new Error("Failed to retrieve ID token.");
//             }
    
//             const idToken = googleUser.authentication.idToken;
//             console.debug("ID Token Retrieved:", idToken);
    
//             // Send the ID token to your backend
//             const result = await onGoogleSuccess({ credential: idToken });
//             console.debug("Backend Response:", result);
    
//             if (result.success) {
//                 toast.success(result.message);
//                 setTimeout(() => {
//                     window.location.href = "/";
//                 }, 1000);
//             } else {
//                 toast.error(result.message);
//             }
//         } catch (error) {
//             console.error("Google Sign-In Error:", error.message, error);
//             toast.error("Google login failed: " + error.message);
//         }
//     };
    
//     return (
//         <>

//         <Helmet>
//   <title>Login - DigiUncle</title>
//   <meta name="description" content="Log in to your DigiUncle account to explore premium home decor products and manage your orders." />
//   <meta name="keywords" content="DigiUncle login, account access, sign in, home decor shopping" />
//   <link rel="canonical" href="https://digiuncle.co.in/login" />
//   <meta property="og:title" content="Login - DigiUncle" />
//   <meta property="og:description" content="Access your DigiUncle account to shop and manage your orders." />
//   <meta property="og:url" content="https://digiuncle.co.in/login" />
//   <meta property="og:type" content="website" />
//   <meta property="og:image" content="https://digiuncle.co.in/image/splash.png" />
//   <meta property="og:site_name" content="DigiUncle Creations"/>
// </Helmet>

// <div
//   className="bg-gray-100 flex justify-center items-center"
//   style={{ height: "85vh", paddingTop: "50px", paddingBottom: "90px" }}
// >

//             <div className="bg-white w-full max-w-md p-8 rounded-lg shadow-lg">
//                 <h1 className="text-3xl font-semibold text-center mb-6">Login</h1>
//                 <form onSubmit={handleSubmit}>
//                     <div className="mb-4">
//                         <label className="block text-md font-medium mb-2" htmlFor="email">Email</label>
//                         <input
//                             className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
//                             type="email"
//                             name="email"
//                             placeholder="Enter your email"
//                             onChange={(e) => setLoginCredentials({ ...loginCredential, email: e.target.value })}
//                             pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
//                             required
//                         />
//                     </div>
//                     <div className="mb-6 relative">
//                         <label className="block text-md font-medium mb-2" htmlFor="password">Password</label>
//                         <input
//                             className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
//                             type={showPassword ? "text" : "password"}
//                             name="password"
//                             placeholder="Enter your password"
//                             onChange={(e) => setLoginCredentials({ ...loginCredential, password: e.target.value })}
//                             required
//                         />
//                         {showPassword ? (
//                             <IoEyeOffOutline
//                                 className="absolute top-[55%] right-3 cursor-pointer text-xl"
//                                 onClick={() => setShowPassword(false)}
//                             />
//                         ) : (
//                             <IoEyeOutline
//                                 className="absolute top-[55%] right-3 cursor-pointer text-xl"
//                                 onClick={() => setShowPassword(true)}
//                             />
//                         )}
//                     </div>
//                     <button
//                         className={`w-full ${loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white py-2 rounded-md font-semibold`}
//                         type="submit"
//                         disabled={loading}
//                     >
//                         {loading ? "Logging in..." : "Login"}
//                     </button>
//                 </form>

//                 <div className="flex justify-between mt-4">
//                     <span
//                         className="text-sm text-blue-600 cursor-pointer"
//                         onClick={() => setShowModal(true)}
//                     >
//                         Forgot your password?
//                     </span>
//                     <span
//                         className="text-sm text-blue-600 cursor-pointer"
//                         onClick={() => navigate("/signup")}
//                     >
//                         Sign up
//                     </span>
//                 </div>

//                 <div className="my-6">
//     {isAndroid ? (
//         <button
//             className="bg-blue-500 text-white py-2 px-4 rounded-md"
//             onClick={handleGoogleSignInAndroid}
//         >
//             Login with Google
//         </button>
//     ) : (
//         <GoogleOAuthProvider clientId={ClientId}>
//             <GoogleLogin
//                 onSuccess={async (response) => {
//                     const result = await onGoogleSuccess(response);
//                     if (result.success) {
//                         toast.success(result.message);
//                         setTimeout(() => {
//                             window.location.href = "/";
//                         }, 1000);
//                     } else {
//                         toast.error(result.message);
//                     }
//                 }}
//                 onError={() => toast.error("Google login failed!")}
//             />
//         </GoogleOAuthProvider>
//     )}
// </div>
//                 <ForgotPasswordModal
//                     isVisible={showModal}
//                     onClose={() => setShowModal(false)}
//                 />
//             </div>
//         </div>
//         </> );
// };

// export default Login;
 
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useAuthContext } from '../context';
import toast from 'react-hot-toast';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'; // For web
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
 
const ForgotPasswordModal = ({ isVisible, onClose }) => {
    const { OTPset } = useAuthContext();
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [useMobile, setUseMobile] = useState(false);
    const navigate = useNavigate();
 
    const handleSendOTP = async () => {
        try {
            const result = useMobile
                ? await OTPset({ mobile })
                : await OTPset({ email });
 
            if (result) {
                toast.success(result.message || "OTP sent successfully");
                onClose();
                navigate('/forgot-otp', {
                    state: {
                        Forgotmobile: useMobile ? mobile : null,
                        Forgotemail: useMobile ? null : email,
                        purpose: 'passwordChange',
                    },
                });
            }
        } catch (error) {
            toast.error("Failed to send OTP");
        }
    };
 
    if (!isVisible) return null;
 
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">Forgot Password</h2>
 
                {useMobile ? (
                    <input
                        type="text"
                        className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none mb-4"
                        placeholder="Enter your registered mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                    />
                ) : (
                    <input
                        type="email"
                        className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none mb-4"
                        placeholder="Enter your registered email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                )}
 
                <div className="flex justify-end space-x-3">
                    <button className="bg-gray-400 text-white px-4 py-2 rounded" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleSendOTP}>
                        Send OTP
                    </button>
                </div>
            </div>
        </div>
    );
};
 
const Login = () => {
    const { loginHandler, loading, onGoogleSuccess } = useAuthContext();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loginCredential, setLoginCredentials] = useState({
        email: "",
        password: "",
    });
 
    const [isAndroid, setIsAndroid] = useState(false);
 
    useEffect(() => {
        const platform = Capacitor.getPlatform();
        if (platform === "android") {
            setIsAndroid(platform === 'android'); // Set isAndroid based on platform
            console.info("Ready for Android Google login using Browser API");
        }
    }, []);
 
    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await loginHandler(loginCredential);
        if (result.success) {
            toast.success(result.message);
            setTimeout(() => {
                window.location.href = "/";
            }, 1000);
        } else {
            console.error("Login failed:", result.message);
        }
    };
 
    const ClientId = "673274437090-f4fd0rh2t4b6evqi860c0bqhe0h2pqb2.apps.googleusercontent.com";
   // const androidClientId = '673274437090-0cs7k07v5dj4k00otr2m4erce3cq2rj2.apps.googleusercontent.com';
 
    // This function handles Google sign-in for Android using the Browser API
    const handleGoogleSignInAndroid = async () => {
        try {
            console.debug("Attempting Google Sign-In on Android...");
     
            const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${ClientId}&redirect_uri=${encodeURIComponent('https://digiuncle.co.in/auth/google/callback')}&scope=email%20profile`;
     
            // Open Google login inside WebView (InAppBrowser)
            const browser = InAppBrowser.create(googleAuthUrl, "_blank", "location=yes,hidden=no,toolbar=no");
     
            // Listen for redirects inside WebView
            browser.on("loadstop").subscribe(async (event) => {
                console.log("WebView Redirect URL:", event.url);
     
                // Ensure we're getting the redirect from Google OAuth
                if (event.url.startsWith("https://digiuncle.co.in/auth/google/callback?")) {
                    browser.close(); // Close WebView
     
                    // Extract the authorization code from URL
                    const urlParams = new URLSearchParams(new URL(event.url).search);
                    const code = urlParams.get("code");
     
                    if (code) {
                        console.debug("Google Auth Code:", code);
     
                        // Send the code to backend for token exchange
                        const result = await onGoogleSuccess({ credential: code });
     
                        if (result.success) {
                            toast.success(result.message);
                            window.location.href = "/";
                        } else {
                            toast.error(result.message);
                        }
                    }
                }
            });
     
        } catch (error) {
            console.error("Google Sign-In Error:", error.message, error);
            toast.error("Google login failed: " + error.message);
        }
    };
   
    return (
        <>
            <Helmet>
                <title>Login - DigiUncle</title>
                <meta name="description" content="Log in to your DigiUncle account to explore premium home decor products and manage your orders." />
                <meta name="keywords" content="DigiUncle login, account access, sign in, home decor shopping" />
                <link rel="canonical" href="https://digiuncle.co.in/login" />
                <meta property="og:title" content="Login - DigiUncle" />
                <meta property="og:description" content="Access your DigiUncle account to shop and manage your orders." />
                <meta property="og:url" content="https://digiuncle.co.in/login" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://digiuncle.co.in/image/splash.png" />
                <meta property="og:site_name" content="DigiUncle Creations"/>
            </Helmet>
 
            <div className="bg-gray-100 flex justify-center items-center" style={{ height: "85vh", paddingTop: "50px", paddingBottom: "90px" }}>
                <div className="bg-white w-full max-w-md p-8 rounded-lg shadow-lg">
                    <h1 className="text-3xl font-semibold text-center mb-6">Login</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-md font-medium mb-2" htmlFor="email">Email</label>
                            <input
                                className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={(e) => setLoginCredentials({ ...loginCredential, email: e.target.value })}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                required
                            />
                        </div>
                        <div className="mb-6 relative">
                            <label className="block text-md font-medium mb-2" htmlFor="password">Password</label>
                            <input
                                className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Enter your password"
                                onChange={(e) => setLoginCredentials({ ...loginCredential, password: e.target.value })}
                                required
                            />
                            {showPassword ? (
                                <IoEyeOffOutline
                                    className="absolute top-[55%] right-3 cursor-pointer text-xl"
                                    onClick={() => setShowPassword(false)}
                                />
                            ) : (
                                <IoEyeOutline
                                    className="absolute top-[55%] right-3 cursor-pointer text-xl"
                                    onClick={() => setShowPassword(true)}
                                />
                            )}
                        </div>
                        <button
                            className={`w-full ${loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white py-2 rounded-md font-semibold`}
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? "Logging in..." : "Login"}
                        </button>
                    </form>
 
                    <div className="flex justify-between mt-4">
                        <span
                            className="text-sm text-blue-600 cursor-pointer"
                            onClick={() => setShowModal(true)}
                        >
                            Forgot your password?
                        </span>
                        <span className="text-sm text-blue-600 cursor-pointer" onClick={() => navigate("/signup")}>
                            Don't have an account? Sign Up
                        </span>
                    </div>
 
                    {/* Google Login */}
                    <div className="mt-6">
                        {isAndroid ? (
                            <button
                                className="w-full bg-red-600 text-white py-2 rounded-md font-semibold"
                                onClick={handleGoogleSignInAndroid}
                            >
                                Sign In with Google
                            </button>
                        ) : (
                                     <GoogleOAuthProvider clientId={ClientId}>
             <GoogleLogin
                 onSuccess={async (response) => {
                    const result = await onGoogleSuccess(response);
                    if (result.success) {
                         toast.success(result.message);
                       setTimeout(() => {
                           window.location.href = "/";
                         }, 1000);
                     } else {
                         toast.error(result.message);
                     }
                }}
                 onError={() => toast.error("Google login failed!")}
             />
         </GoogleOAuthProvider>
                        )}
                    </div>
                </div>
            </div>
 
            <ForgotPasswordModal isVisible={showModal} onClose={() => setShowModal(false)} />
        </>
    );
};
 
export default Login;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import EditProductForm from './EditProductForm';
import toast from 'react-hot-toast';
import { useNavigate, Link } from 'react-router-dom';
import { deleteProductURL, getproductURL } from '../../api/api';
import { useAuthContext } from '../../context';
import MainLoader from '../../pages/MainLoader';
 
const ProductManagement = () => {
  const { user } = useAuthContext(); // Fix: Call useAuthContext
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
 
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
 
  // Redirect unauthorized users
  useEffect(() => {
    if (!user) return; // Ensures user is available
    if (user.type !== 'admin') {
      toast.error('Unauthorized access');
      navigate('/');
    }
  }, [user, navigate]);
 
// Fetch products function (defined outside useEffect)
const fetchProducts = async () => {
  setLoading(true);
  try {
    const res = await axios.get(getproductURL, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    if (res.data && res.data.data) {
      setProducts(res.data.data);
      localStorage.setItem('products', JSON.stringify(res.data.data));
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    setError('Error fetching products');
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
  }
};
 
// useEffect to call fetchProducts on component mount or token change
useEffect(() => {
  if (token) fetchProducts();
}, [token]);
 
const handleSuccess = () => {
  fetchProducts();
  handleModalClose();
};
 
  // Handle Delete Product
  const handleDeleteProduct = async () => {
    try {
      const res = await axios.delete(deleteProductURL, {
        headers: { Authorization: `Bearer ${token}` },
        data: { SKU_id: deleteProductId },
      });
 
      if (res.status === 200) {
        const updatedProducts = products.filter((product) => product.SKU_id !== deleteProductId);
        setProducts(updatedProducts);
        localStorage.setItem('products', JSON.stringify(updatedProducts));
 
        const recentProducts = JSON.parse(localStorage.getItem('Recent')) || [];
        const updatedRecentProducts = recentProducts.filter((product) => product.SKU_id !== deleteProductId);
        localStorage.setItem('Recent', JSON.stringify(updatedRecentProducts));
 
        toast.success(res.data.message || 'Product deleted successfully!');
      } else {
        toast.error(res.data.message || 'Failed to delete product.');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error('An error occurred while deleting the product.');
    }
    fetchProducts();
    setIsDeleteModalOpen(false);
  };
 
  // Handle Edit Product
  const handleEditProduct = (productId) => {
    setEditProductId(productId);
    setIsModalOpen(true);
  };
 
  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditProductId(null);
  };
 
  // if (loading || !user) return  <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
  //                                 <MainLoader />
  //                               </div>;

const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        let timer;
        if (!loading || !user) {
            timer = setTimeout(() => {
                setShowLoader(false);
            }, 1200); 
        } else {
            setShowLoader(true); 
        }

        return () => clearTimeout(timer); 
    }, [loading]);

    if (showLoader) {
        return (
            <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                <MainLoader />
            </div>
        );
    }

  if (error) return <div>{error}</div>;

if(user.type ==='admin'){
  return (
    <div className="container mx-auto p-4 md:mb-0 mb-20">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Product Listings</h1>
        <Link to="/add-product" state={{ token }}>
          <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 ml-auto">
            Add Product
          </button>
        </Link>
      </div>
 
      <div className="overflow-x-auto max-h-[400px] border border-gray-300 rounded-lg shadow-md">
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 px-4 py-2">Image</th>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">SKU</th>
              <th className="border border-gray-300 px-4 py-2">Price</th>
              <th className="border border-gray-300 px-4 py-2">Stock</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.SKU_id} className="hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2">
                  <img src={product.image[0]} alt={product.name} className="h-16 w-16 object-cover rounded-md" />
                </td>
                <td className="border border-gray-300 px-4 py-2">{product.name}</td>
                <td className="border border-gray-300 px-4 py-2">{product.SKU_id}</td>
                <td className="border border-gray-300 px-4 py-2">₹{product.selling_price}</td>
                <td className="border border-gray-300 px-4 py-2">{product.IN_stock === 'in-stock' ? 'In Stock' : 'Out of Stock'}</td>
                <td className="border border-gray-300 px-4 py-2">
                  <button className="bg-blue-500 text-white px-2 py-1 rounded-md mr-2 hover:bg-blue-600" onClick={() => handleEditProduct(product.SKU_id)}>
                    <FaEdit className="inline mr-1" /> Edit
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                    onClick={() => { setDeleteProductId(product.SKU_id); setIsDeleteModalOpen(true); }}
                  >
                    <FaTrash className="inline mr-1" /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
 
        {/* Edit Product Modal */}
        {isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 w-3/4 max-w-2xl h-[70vh] overflow-y-auto relative">
      {/* Close Button */}
      <button
        onClick={handleModalClose}
        className="absolute top-4 right-4 text-gray-500 hover:text-black text-2xl"
      >
        &times;
      </button>

      {/* Form Content (Scrolls if needed) */}
      <div className="h-full">
        <EditProductForm
          token={token}
          onSuccess={handleSuccess}
          initialSKUId={editProductId}
        />
      </div>
    </div>
  </div>
)}

         {isDeleteModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <p className="mb-4 text-lg font-semibold">Are you sure you want to delete this product?</p>
              <button className="bg-red-500 text-white px-4 py-2 rounded-md mr-2" onClick={handleDeleteProduct}>Yes</button>
              <button className="bg-gray-500 text-white px-4 py-2 rounded-md" onClick={() => setIsDeleteModalOpen(false)}>No</button>
            </div>
          </div>
        )}
      </div>
    );
  }
};
 
export default ProductManagement;
 
 
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import ReactStars from 'react-stars';
import { IoBagAdd, IoCartOutline, IoHeartOutline} from "react-icons/io5";
import { useCartContext, useWishlistContext, useProductContext } from '../../context';
import Modal from '../Modal/CategoryModal';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import GoogleAd from '../GoogleAdSense/GoogleAdSense';
 
const ProductDetail = () => {
  const data = JSON.parse(localStorage.getItem("products")) || [];
  const { id } = useParams();
  const filterdata = data.filter((i) => i.SKU_id === id);
  const { addToCart, state } = useCartContext();
  const { Cart = [] } = state || {};
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [productReviews, setProductReviews] = useState([]);
  const [navigateToCart, setNavigateToCart] = useState(false);
  const { addRecentProduct } = useProductContext();
  const { Wishlist, addToWishlist, removeFromWishlist } = useWishlistContext();
  const navigate = useNavigate();
  
  useEffect(() => {
    const allReviews = JSON.parse(localStorage.getItem("reviews")) || [];
    const currentProductReviews = allReviews.filter(review => review.SKU_Id === filterdata[0]?.SKU_id);
    setProductReviews(currentProductReviews);
  }, [id]);
 
  useEffect(() => {
    filterdata.forEach(product => {
      addRecentProduct(product);
    });
  });
 
  const isInWishlist = (productId) => {
    return Wishlist.some((wishlistItem) => wishlistItem.SKU_id === productId);
};
 
  const handleAddToWishlist = (item) => {
    if (!isInWishlist(item.SKU_id)) {
      addToWishlist(item);
    }
  };
 
  const handleRemoveFromWishlist = (itemId) => {
    removeFromWishlist(itemId);
  };
 
  const handleImageClick = (index) => setCurrentImageIndex(index);
 
  const handleAddToCart = (product) => {
    addToCart(product);
  };
 
  const handleBuyNow = (product) => {
    if (product.Links && product.Links.length > 0) {
      // If product has a link, open it in a new tab
      window.open(product.Links[0], "_blank");
    } else {
      // If product has no link, proceed with normal cart checkout flow
      if (!Cart) return;
      const existingItem = Cart.find(cartItem => cartItem._id === product._id);
      if (!existingItem) {
        addToCart(product);
      }
      setNavigateToCart(true);
    }
  };  
 
  const scrollToTop = () => window.scrollTo(0, 0);
 
  const averageRating = productReviews.length > 0
    ? productReviews.reduce((acc, review) => acc + review.user_rating, 0) / productReviews.length
    : 0;
 
  useEffect(() => {
    if (navigateToCart) {
      setTimeout(() => {
        navigate('/cart');
      }, 100);
    }
  }, [navigateToCart, Cart, navigate]);
 
  const relatedProducts = data.filter(
    (product) => product.category === filterdata[0]?.category && product._id !== filterdata[0]?._id
  );

  // Structured data (JSON-LD)
  const structuredData = filterdata.map(product => ({
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.name,
    "image": product.image[0], // assuming the first image is the main image
    "description": product.description,
    "sku": product.SKU_id,
    "brand": {
      "@type": "Brand",
      "name": product.brand || "Unknown"
    },
    "offers": {
      "@type": "Offer",
      "url": window.location.href,
      "priceCurrency": "INR",
      "price": product.selling_price || 0,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": product.IN_stock === "in-stock" ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
      "priceValidUntil": "2025-12-31",
      "seller": {
        "@type": "Organization",
        "name": "DigiUncle"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": averageRating || 0,
      "reviewCount": productReviews.length || 0
    }
  }));
 
  return (
    <>
    <Helmet>
        <meta property="og:site_name" content="DigiUncle Creations" />
      </Helmet>
      {filterdata.map((product) => (
        <div key={product._id} className="container mx-auto my-8 p-4">
          {/* Structured Data Script */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(structuredData[0])
            }}
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="flex flex-col items-center">
  <div className="w-full md:w-[500px] max-w-full h-auto mb-4 relative">
    {/* Watermark Overlay */}
    {product.IN_stock === "out-stock" && (
      <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center z-10 pointer-events-none">
        <span className="text-white font-bold text-2xl">Out of Stock</span>
      </div>
    )}
    
    {/* Product Image */}
    {product.image.length > 0 && (
      <img
        src={product.image[currentImageIndex]}
        className="w-full object-cover rounded-lg shadow-md"
        alt={product.name}
      />
    )}
    
    {/* Wishlist Button */}
    <button
      className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
      onClick={() =>
        isInWishlist(product.SKU_id)
          ? handleRemoveFromWishlist(product.SKU_id)
          : handleAddToWishlist(product)
      }
    >
      <FaHeart
        className={`text-gray-500 ${
          isInWishlist(product.SKU_id) ? "text-red-500" : ""
        }`}
      />
    </button>
  </div>

  {/* Thumbnail Images */}
  <div className="flex space-x-3 overflow-x-auto">
    {product.image.map((img, index) => (
      <div
        key={index}
        className="h-20 w-20 border rounded-md cursor-pointer hover:opacity-75 transition-opacity duration-200"
        onClick={() => handleImageClick(index)}
      >
        <img
          src={img}
          alt={`${product.name} ${index + 1}`}
          className="h-full w-full object-cover rounded-md"
        />
      </div>
    ))}
  </div>

{/* Buttons */}
<div
  className={`grid ${
    product.IN_stock === "out-stock" ? "grid-cols-1" : "grid-cols-2"
  } gap-4 mt-6 w-full`}
>
  {/* Conditionally Render Add to Cart and Buy Now Buttons */}
  {product.IN_stock !== "out-stock" ? (
   <>
    {product.Links && product.Links.length > 0 ? (
        // If product has links, show only the Buy Now button centered
        <>
          <button
            className="bg-orange-500 w-full md:w-[40vw] py-5 text-2xl md:ml-10 font-semibold text-white hover:bg-orange-600 rounded-lg transition duration-200"
            onClick={() => handleBuyNow(product)}
          >
            <IoBagAdd className="inline mr-2" />
            Buy Now
          </button>
        </>
      ) : (  // If no links, show both Add to Cart and Buy Now buttons with more width
        <>
          <button
            className="bg-yellow-500 w-full py-5 text-xl font-semibold text-white hover:bg-yellow-600 rounded-lg transition duration-200"
            onClick={() => handleAddToCart(product)}
          >
            <IoCartOutline className="inline mr-2" /> Add to Cart
          </button>
          <button
            className="bg-orange-500 w-full py-5 text-xl font-semibold text-white hover:bg-orange-600 rounded-lg transition duration-200"
            onClick={() => handleBuyNow(product)}
          >
            <IoBagAdd className="inline mr-2" />
            Buy Now
          </button>
          </>
      )}
    </>

  ) : (
    /* Add to Wishlist Button */
    <button
      className="bg-blue-500 w-full py-3 text-lg font-semibold text-white hover:bg-blue-600 rounded-lg transition duration-200"
      onClick={() => handleAddToWishlist(product)}
    >
      <IoHeartOutline className="inline mr-2" /> Add to Wishlist
    </button>
  )}
</div>
</div>

            <div className="space-y-4 w-full">
              <div className="border p-4 rounded-lg shadow-lg">
              <h1 className="text-3xl font-bold text-gray-800 break-words overflow-hidden text-wrap">{product.name}</h1>
                <div className="flex items-center space-x-3 mt-2">
                  <p className="text-xl text-gray-700 font-semibold">₹{product.selling_price || "Price not available"}</p>
                  {product.MRP_price && (
                    <span className="text-green-600 font-medium text-sm">
                      (MRP: ₹{product.MRP_price})
                    </span>
                  )}
                   <span className="ml-2 text-green-700 font-semibold text-sm">
                        {((product.MRP_price - product.selling_price) /product.MRP_price * 100).toFixed(0)}% Off
                      </span>
                </div>
                <div className="flex items-center mt-2">
                  <ReactStars count={5} value={averageRating} size={24} color={'#ffd700'} edit={false} />
                  <span className="ml-2 text-gray-600">
                    {productReviews.length > 0 ? `${productReviews.length} reviews` : 'No reviews yet'}
                  </span>
                </div>
              </div>
 
              {/* Description */}
              <div className="border p-4 rounded-lg shadow-lg">
                <h2 className="font-bold text-xl">Description</h2>
                <p className="text-gray-700 mt-2">{product.description || "No description available."}</p>
              </div>
 
              {/* Show More Details Button */}
              <button
                className="text-blue-500 mt-4 focus:outline-none"
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? 'Hide Details' : 'Show More Details'}
              </button>
 
              {/* Additional Product Details (toggleable) */}
              {showDetails && (
     <div className="border p-4 rounded-lg shadow-lg mt-4">
    <h2 className="font-bold text-2xl mb-4">Product Details</h2>
    {Object.entries(product).map(([key, value]) => {
      if (value && !["Links", "_id", "user_id", "image", "createdAt", "updatedAt", "description", "listing","__v"].includes(key)) {
        const label = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
        let formattedValue = value;

        // Format price fields
        if (key === "MRP_price" || key === "selling_price") {
          formattedValue = `₹${value}`;
        }

        // Handle IN_stock field
        if (key === "IN_stock") {
          formattedValue = value.toLowerCase() === "in-stock" ? "Available to buy" : "Not available";
        }

        return (
          <div key={key} className="flex justify-between mb-2">
            <span className="font-semibold">{label}:</span>
            <span className="text-gray-700">{formattedValue}</span>
          </div>
        );
      }

      return null;
    })}
  </div>
)}

 
              {/* Customer Reviews Section */}
              <div className="border p-4 rounded-lg shadow-lg mt-4">
                <h2 className="font-bold text-xl mb-4">Customer Reviews</h2>
                <div className="max-h-60 overflow-y-auto space-y-4">
                  {productReviews.length > 0 ? (
                    productReviews.map((review, index) => (
                      <div key={index} className="border-b pb-3 mb-3">
                        <div className="flex items-center">
                          <span className="font-semibold">{review.user_name}:</span>
                          <ReactStars
                            count={5}
                            value={review.user_rating}
                            size={20}
                            color={'#ffd700'}
                            edit={false}
                          />
                        </div>
                        <p className="text-gray-600">{review.user_review}</p>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">No reviews yet.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
 
        {/* Related Products Section */}
       {relatedProducts.length > 0 && (
  <div className="mt-8 md:mb-0 mb-10">
    {/* Related Products Header */}
    <h2 className="mx-auto bg-blue-200 text-center rounded-full font-serif font-semibold text-2xl sm:text-3xl md:text-4xl h-16 sm:h-20 md:h-24 w-[80%] sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-2/4 flex items-center justify-center leading-none">
      Related Products
    </h2>
 
    {/* Grid Layout for Related Products */}
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8 px-4">
      {relatedProducts.map((relatedProduct) => (
        <div key={relatedProduct._id} className="border p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-200 flex flex-col justify-between">
          {/* Product Image */}
          <div className="relative flex justify-center w-full mb-4">
       
            <img
              src={relatedProduct.image[0]}
              alt={relatedProduct.name}
              className="w-full h-[38vh] object-contain rounded-md transition-all duration-300 transform hover:scale-105 cursor-pointer"
              onClick={() => { navigate(`/product/${relatedProduct.SKU_id}`); scrollToTop(); }}
            />
            <button
              className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
              onClick={() =>
                isInWishlist(relatedProduct.SKU_id)
                  ? handleRemoveFromWishlist(relatedProduct.SKU_id)
                  : handleAddToWishlist(relatedProduct)
              }
            >
              <FaHeart
                className={`text-gray-500 ${isInWishlist(relatedProduct.SKU_id) ? 'text-red-500' : ''}`}
              />
            </button>
          </div>
 
          {/* Related-Product Details */}
          <div className="text-center">
            <Link to={`/product/${relatedProduct.SKU_id}`} onClick={() => scrollToTop()}>
            <h3 className="text-sm sm:text-lg font-semibold text-gray-800 truncate">{relatedProduct.name}</h3>
           
            <span className="text-sm sm:text-base font-semibold text-gray-700">
              ₹{relatedProduct.selling_price} &nbsp;
            </span>
            {relatedProduct.MRP_price && (
              <span className="text-xs sm:text-sm line-through text-red-500">
                ₹{relatedProduct.MRP_price}
              </span>
            )}
             <span className="ml-2 text-green-600 text-sm">
                        {((relatedProduct.MRP_price - relatedProduct.selling_price) / relatedProduct.MRP_price * 100).toFixed(0)}% Off
                      </span>
                      </Link>
            <button
              className="bg-yellow-500 mt-2 py-1 w-full text-sm sm:text-lg font-semibold text-white hover:bg-yellow-600 rounded-lg transition duration-200"
              onClick={() => handleAddToCart(relatedProduct)}
            >
              <IoCartOutline className="inline mr-2" /> Add to Cart
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
)}
 {/* <div className='my-2'>
                <GoogleAd />
                </div> */}
          {/* Modal for Links */}
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} links={product.Links} />
        </div>
      ))}
    </>
  );
};
 
export default ProductDetail;
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Navbar } from '../components/index';
import MainLoader from '../pages/MainLoader';

const SharedLayout = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating an API call or waiting for data
        setTimeout(() => {
            setLoading(false);
        }, 3500); // Adjust timing as needed
    }, []);

    return (
        <div className="relative">
            {/* Loader that stays on top while content loads */}
            {loading && (
                <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                    <MainLoader />
                </div>
            )}

            {/* Main content (renders behind the loader) */}
            <div className={`transition-opacity duration-500 ${loading ? "opacity-0" : "opacity-100"}`}>
                <Navbar />
                <div>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default SharedLayout;

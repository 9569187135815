import React, { useState } from 'react';
import { FaPlus, FaMinus } from "react-icons/fa";
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useCartContext, useAuthContext } from '../context';
 
import "../App.css";
import { useWishlistContext } from '../context';
 
const Cart = () => {
  const navigate = useNavigate();
  const { Decrease, Increase, Cart, removeItem } = useCartContext();
  const { OTPset } = useAuthContext();
  const { addToWishlist } = useWishlistContext();
 
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState('');
  const {user}= useAuthContext();
 
 
  // Calculate total price, discount amount, and final price
  const Price = Cart.reduce((total, item) => total + item.MRP_price * item.productcount, 0);
  const totalDiscountAmount = Cart.reduce((totalDiscount, item) => {
    const discount = (item.MRP_price - item.selling_price) * item.productcount;
    return totalDiscount + (discount > 0 ? discount : 0);
  }, 0);
 
  const totalAmountAfterDiscount = Price - totalDiscountAmount;
 
  const handleProceedToCheckout = async () => {
    if (!user?.email && !user?.mobile) {
      toast.error("Email or mobile is required to proceed.");
      return;
    }
 
    if (!user?.isEmailVerified && !user?.isMobileOtpVerified) {
      setShowVerificationPopup(true);
      return;
    }
    else{
    // Navigate with applied promo only if it is valid
      navigate("/checkout");
    }
  };
 
  const handleVerificationSelection = async () => {
    if (!selectedVerificationMethod) {
      toast.error("Please select a verification method.");
      return;
    }
 
    try {
      const result = await OTPset({ email: user?.email, mobile: user?.mobile });
      if (result) {
        toast.success(`OTP sent to your ${selectedVerificationMethod}`);
        navigate('/verify-otp', {
          state: {
            purpose: selectedVerificationMethod === 'email' ? 'emailVerification' : 'mobileVerification',
            fromcart: true,
          },
        });
      }
    } catch (error) {
      toast.error(`Failed to send OTP to ${selectedVerificationMethod}`);
    }
 
    setShowVerificationPopup(false);
  };
 
 
  const handleAddToWishlist = (item) => {
    addToWishlist(item); // Call addToWishlist function to add the item
  };
 
  const scrollToTop = () => window.scrollTo(0, 0);
 
  const navigateToProductDetails = (skuId) => {
    navigate(`/product/${skuId}`);
    scrollToTop();
  };
 
  return (
    <>
      {Cart?.length > 0 ? (
        <div className="md:py-10 w-screen h-fit">
          <h1 className="text-4xl font-extrabold text-center text-gray-800">Your Cart</h1>
          {/* Cart Items */}
          <div className="flex flex-wrap md:py-10 w-screen md:w-[140vw]">
            <div className="w-full md:w-2/5 px-4 h-fit overflow-y-auto rounded-xl md:mx-8">
              <div className="bg-gray-50 shadow-md rounded-lg mb-5 py-3 px-5 flex justify-between items-center">
                <span className="text-sm font-medium">Total Unique Items</span>
                <button className="px-3 py-1 border rounded text-sm">{Cart?.length}</button>
              </div>
              {Cart?.map((item) => (
                <div key={item._id} className="bg-white shadow-sm rounded-lg mb-4 p-4 flex gap-4 md:pl-12 flex-wrap">
                  {/* Item Details */}
                  <div
                    className="flex flex-col items-center gap-4 w-full sm:w-auto cursor-pointer"
                    onClick={(e) => {
                      // This will allow navigation only when the image itself is clicked.
                      if (e.target.tagName !== "BUTTON") {
                        navigateToProductDetails(item.SKU_id);
                        scrollToTop();
                      }
                    }}
                  >
                    {/* Product Image */}
                    <img
                      src={item.image[0]}
                      alt="product"
                      className="h-28 w-28 rounded-md object-cover"
                    />
                   
                    {/* Quantity Control */}
                    <div className="flex items-center gap-2 border rounded-full px-2 py-1 mt-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent navigation when the button is clicked
                          Decrease(item);
                        }}
                        className="text-lg"
                      >
                        <FaMinus />
                      </button>
                      <span className="px-2">{item.productcount}</span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent navigation when the button is clicked
                          Increase(item);
                        }}
                        className="text-lg"
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                  <div className="flex-1 w-full sm:w-auto cursor-pointer" onClick={() => { navigateToProductDetails(item.SKU_id); scrollToTop(); }}>
                    <h3 className="text-lg font-semibold">{item.name}</h3>
                    <p className="text-gray-600 text-sm">Color: {item.product_color}</p>
                    <p className="text-gray-600 text-sm">Seller: {item.brand}</p>
 
                    <div className="mt-2">
                      <span className="line-through text-gray-500 mr-2">{`₹${item.MRP_price}`}</span>
                      <span className="text-lg font-bold text-black">{`₹${item.selling_price}`}</span>
                      <span className="ml-2 text-green-600 text-sm">
                        {((item.MRP_price - item.selling_price) / item.MRP_price * 100).toFixed(0)}% Off
                      </span>
                    </div>
                  </div>
 
                  <div className="flex flex-col sm:flex-row items-center justify-between gap-4 mt-3 md:mt-0 w-full">
                    <button
                      className="text-sm text-green-600 font-medium mb-2 sm:mb-0 w-full sm:w-auto"
                      onClick={() => handleAddToWishlist(item)}
                    >
                      Add to Wishlist
                    </button>
                    <button
                      onClick={() => { removeItem(item) }}
                      className="text-sm text-red-500 font-medium w-full sm:w-auto"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
 
            {/* Price Details */}
            <div className="w-full md:w-1/4 px-4 mb-20 md:mb-0">
              <div className="bg-white shadow-md rounded-lg p-5">
                <h2 className="text-xl font-bold mb-4 text-gray-800">Price Details</h2>
 
                <div className="flex justify-between mb-3">
                  <span className="text-base text-gray-600">Price ({Cart?.length} items)</span>
                  <span className="text-base font-semibold text-gray-900">₹{Price.toFixed(2)}</span>
                </div>
 
                <div className="flex justify-between mb-3">
                  <span className="text-base text-gray-600">Discount</span>
                  <span className="text-base font-semibold text-green-600">-₹{totalDiscountAmount.toFixed(2)}</span>
                </div>
 
                <hr className="my-4" />
 
                <div className="flex justify-between mb-3">
                  <span className="text-lg font-semibold text-gray-800">Total Amount</span>
                  <span className="text-lg font-semibold text-gray-900">₹{totalAmountAfterDiscount.toFixed(2)}</span>
                </div>
 
                <button
                  onClick={handleProceedToCheckout}
                  className="w-full bg-blue-600 text-white font-semibold py-3 rounded-md mt-4"
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
 
          {/* Verification Popup */}
          {showVerificationPopup && (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50 ">
              <div className="bg-white p-6 rounded-lg shadow-md w-96">
                <h3 className="text-lg font-semibold mb-4 text-gray-800">Select Verification Method</h3>
                <div className="flex flex-col space-y-3">
                  <button
                    className={`border ${selectedVerificationMethod === 'email' ? 'bg-blue-600 text-white' : 'bg-white text-gray-800'} rounded-md px-4 py-2`}
                    onClick={() => setSelectedVerificationMethod('email')}
                  >
                    Verify via Email
                  </button>
                  {/* Uncomment below if mobile verification is needed */}
                  {/* <button
                    className={`border ${selectedVerificationMethod === 'mobile' ? 'bg-blue-600 text-white' : 'bg-white text-gray-800'} rounded-md px-4 py-2`}
                    onClick={() => setSelectedVerificationMethod('mobile')}
                  >
                    Verify via Mobile
                  </button> */}
                </div>
                <button
                  onClick={handleVerificationSelection}
                  className="mt-4 w-full bg-green-600 text-white py-2 rounded-md"
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-16 sm:py-24 md:mb-0 mb-12">
          <div className="text-center my-8">
            <h1 className="text-4xl font-extrabold text-gray-800">Your Cart is Empty</h1>
          </div>
          <img
            src="./image/EmptyCart.gif"
            alt="Empty Cart"
            className="w-3/4 sm:w-1/2 mb-4"
            style={{ background: 'transparent' }}
          />
          <Link
            to="/"
            className="
              flex items-center justify-center
              text-black text-sm sm:text-base md:text-lg
              text-center hover:text-blue-900
              bg-yellow-500
              h-10 sm:h-12 md:h-14
              w-3/4 sm:w-1/3 md:w-1/6
              mx-auto
              rounded-md"
              onClick={scrollToTop} 
          >
            <b>SHOP NOW</b>
          </Link>
        </div>
      )}
    </>
  );
};
 
export default Cart;
 
 
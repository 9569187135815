import { ProductDetail, ProductList } from "../components";
import {  Home, Settings, SignIn,SignUp, TermsAndConditions, CancelationAndRefund, ShippingAndDelivery,PrivacyPolicy, ContactUs, AboutUs, Offlinestore } from "../pages";
import ForgotPasswordOtp from "../pages/ForgotPasswordOtp";
import ResetPassword from "../pages/ResetPassword";
export const authRoutes = [
    {
        path : '/login',
        element : <SignIn/>,
    },
    {
        path : '/signup',
        element : <SignUp/>,
    },
]
 
export const contentRoutes = [
    {
        path : '/product/:id',
        element : <ProductDetail/>,
    },
    {
        path : '/productlist',
        element : <ProductList/>,
    },
    {
        path : '/terms',
        element : <TermsAndConditions/>,
    },
    {
        path : '/cancellation',
        element : <CancelationAndRefund/>,
    },
    {
        path : '/shipping',
        element : <ShippingAndDelivery/>,
    },
    {
        path : '/privacypolicy',
        element : <PrivacyPolicy/>,
    },
    {
        path : '/contactus',
        element : <ContactUs/>,
    },
    {
        path : '/aboutus',
        element : <AboutUs/>,
    },
    {
        path : '/offlinestore',
        element : <Offlinestore/>,
    },
    {
        path : '/forgot-otp',
        element : <ForgotPasswordOtp/>,
    },
    {
        path : '/settings',
        element : <Settings/>,
    },
    {
        path : '*',
        element : <Home/>,
    },
    {
        path : '/reset-password',
        element : <ResetPassword/>,
    },
];
 
 
 
import {React,useState, useEffect } from 'react';
import axios from 'axios';
import { FaRegUser, FaRegEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { userUpdateURL } from '../api/api';
import { useAuthContext } from '../context';

const Profile = () => {
  const navigate = useNavigate();
  const { OTPset, user, fetchUser, token } = useAuthContext();
  const [data, setData] = useState("");
  const [reloading, setReloading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        await fetchUser(); // Ensure user data is fetched
      }
      if (user) {
        setData({
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          mobile: user.mobile || '',
        });
      }
      setLoading(false); // Stop loading when data is available
    };
    fetchData();
  }, [user, fetchUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setReloading(true);
    try {
      const response = await axios.patch(
        userUpdateURL,
        { ...data, id: user?.user_id }, // Safe access
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message);
      localStorage.setItem('userData', JSON.stringify(response.data.user)); // Update storage
      setReloading(false);
      fetchUser(); 
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error(`An error occurred: ${error.message}`);
      setReloading(false);
    }
  };

  const handleOTP = async () => {
    try {
      const email = user?.email;
      if (!email) return;
      const result = await OTPset({ email });
      if (result) {
        toast.success("OTP sent to email");
        navigate('/verify-otp', { state: { purpose: 'passwordChange' } });
      }
    } catch (error) {
      console.error("Error in handleOTP:", error);
      toast.error("An error occurred while sending OTP");
    }
  };

  const handleEmail = async () => {
    const email = user?.email || '';
    if (!email) {
      toast.error("Email is required to proceed.");
      return;
    }
    if (!user?.isEmailVerified) {
      try {
        const result = await OTPset({ email });
        if (result) {
          toast.success("OTP sent to email");
          navigate('/verify-otp', { state: { email, purpose: 'emailVerification', fromProfile: true } });
          return;
        }
      } catch (error) {
        toast.error("Failed to send OTP");
        return;
      }
    } else {
      navigate("/profile");
      toast.success("Email Verification Successful");
    }
  };

  if (loading) {
    return <p className="text-center text-gray-600">Loading profile...</p>;
  }


  return (
    <div className="h-fit flex items-center justify-center py-6 px-4 sm:px-8 mb-12 md:mb-0">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg w-full max-w-4xl ">
        <div className="flex flex-col lg:flex-row">
          {/* Profile Picture and Status */}
          <div className="w-full lg:w-1/3 flex flex-col items-center p-4 border-b lg:border-b-0 lg:border-r lg:border-r-2">
            <div className="profile-section mb-4">
              {user?.picture ? (
                <img
                  src={user?.picture}
                  alt={`${user?.firstName} ${user?.lastName}`}
                  className="profile-picture w-20 h-20 rounded-full"
                />
              ) : (
                <FaRegUser className="text-4xl text-gray-600" />
              )}
            </div>
            <h1 className="text-lg font-semibold">{user?.firstName} {user?.lastName}</h1>
            <span className="bg-green-500 text-white text-sm px-3 py-1 rounded mt-1">Active</span>
            {user?.isEmailVerified && user?.password && (
              <button
                onClick={handleOTP}
                className="bg-yellow-600 text-white text-sm px-4 py-2 mt-2 rounded-md hover:bg-yellow-700 transition duration-300"
              >
                Reset Password
              </button>
            )}
            {!user?.isEmailVerified && (
              <div className="flex flex-wrap justify-center gap-2 mt-2">
                <button
                  onClick={handleEmail}
                  className="bg-blue-500 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Verify Email
                </button>
                <button
                  onClick={handleOTP}
                  className="bg-yellow-600 text-white text-sm px-4 py-2 rounded-md hover:bg-yellow-700 transition duration-300"
                >
                  Reset Password
                </button>
              </div>
            )}
          </div>

          {/* Profile Details */}
          <div className="w-full lg:w-2/3 p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-red-600">Your Profile</h2>
              <button
                className="flex items-center text-blue-500 gap-2"
                onClick={() => setEditMode(!editMode)}
              >
                <FaRegEdit size={20} />
                <span>Edit/Update</span>
              </button>
            </div>

            {editMode ? (
              <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="block font-semibold">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Contact No.</label>
                  <input
                    type="text"
                    name="mobile"
                    value={data.mobile}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div className="col-span-1 sm:col-span-2 text-right">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                  >
                     {reloading?'Loading....':'Save'}
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="font-bold text-lg">First Name:</label>
                  <p className="text-gray-700 text-sm">{user?.firstName}</p>
                </div>
                <div>
                  <label className="font-bold text-lg">Last Name:</label>
                  <p className="text-gray-700 text-sm">{user?.lastName}</p>
                </div>
                <div>
                  <label className="font-bold text-lg">Email:</label>
                  <p className="text-gray-700 text-sm">{user?.email}</p>
                </div>
                <div>
                  <label className="font-bold text-lg">Contact No.:</label>
                  <p className="text-gray-700 text-sm">
                    {user?.mobile ? user?.mobile : "Update your profile to add a contact number!"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

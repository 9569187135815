import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet'; 
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FaHeart } from 'react-icons/fa';
import $ from 'jquery';
import "./page.css";
import { ProductContext } from '../../context/productContext/ProductContext';
import { useWishlistContext } from '../../context';
import { getproductURL } from "../../api/api";
import ReactStars from 'react-stars';
import MainLoader from '../../pages/MainLoader';
import GoogleAd from '../GoogleAdSense/GoogleAdSense';

const ProductListing = () => {
    const navigate = useNavigate();
    const { addRecentProduct } = useContext(ProductContext);
    const { Wishlist, addToWishlist, removeFromWishlist } = useWishlistContext();
    const [data, setData] = useState([]);
    const [range, setRange] = useState();
    const [filterPrice, setFilterPrice] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isBrandOpen, setIsBrandOpen] = useState(false); // State to control the brand filter visibility
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [sortOption, setSortOption] = useState('');
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [reviews, setReviews] = useState([]);
    const scrollToTop = () => window.scrollTo(0, 0);
 
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(getproductURL);  
                const fetchedData = Array.isArray(res.data.data) ? res.data.data : [];
                setData(fetchedData);
                setFilterPrice(fetchedData);
                const prices = fetchedData.map((item) => Number(item.selling_price) || 0);
                setMinPrice(Math.min(...prices));
                setMaxPrice(Math.max(...prices));
                setRange(Math.min(maxPrice));
            } catch (error) {
                console.error("Error fetching data:", error);
                setData([]);
            } finally {
                setLoading(false);
            }
        };
    
        getData();
    }, [maxPrice]);
    
    useEffect(() => {
        const filterData = () => {
            let filtered = [...data];
            if (selectedBrand) {
                filtered = filtered.filter(item => item.brand === selectedBrand);
            }
            if (selectedCategory) {
                filtered = filtered.filter(item => item.category === selectedCategory);
            }
            filtered = filtered.filter(item => Number(item.selling_price) <= range);
            filtered = handleSort(sortOption, filtered);
            setFilterPrice(filtered);
        };
        filterData();
    }, [selectedBrand, selectedCategory, range, sortOption, data]);    
    
    const isInWishlist = (productId) => {
        return Wishlist.some((wishlistItem) => wishlistItem.SKU_id === productId);
    };
   
    const handleAddToWishlist = (item) => {
        if (!isInWishlist(item.SKU_id)) {
            addToWishlist(item);
        }
    };
   
    const handleRemoveFromWishlist = (itemId) => {
        removeFromWishlist(itemId);
    };
 
    const handleChange = (value) => {
        const numericValue = Number(value);
        setRange(numericValue);
 
        let filtered = data.filter((item) => {
            const itemPrice = Number(item.selling_price) || 0;
            return (!selectedCategory || item.category === selectedCategory) && itemPrice <= numericValue;
        });
 
        filtered = handleSort(sortOption, filtered);
        setFilterPrice(filtered);
    };
 
    const calculateAverageRating = (productId) => {
        const productReviews = reviews.filter(review => review.SKU_Id === productId);
        const totalRating = productReviews.reduce((acc, review) => acc + review.user_rating, 0);
        return productReviews.length > 0 ? totalRating / productReviews.length : 0;
    };
 
    const uniqueCategories = Array.from(new Set(data.map((item) => item.category)));
    const uniqueBrands = Array.from(new Set(data.map((item) => item.brand))); // Get unique brands
 
    const handleSort = (option, dataToSort) => {
        setSortOption(option);
        let sortedArray;
        switch (option) {
            case 'Price--Low to High':
                sortedArray = [...dataToSort].sort((a, b) => a.selling_price - b.selling_price);
                break;
            case 'Price--High to Low':
                sortedArray = [...dataToSort].sort((a, b) => b.selling_price - a.selling_price);
                break;
            case 'Newest first':
                sortedArray = [...dataToSort].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            default:
                sortedArray = dataToSort;
        }
        return sortedArray;
    };

    const handleBrandClick = (brand) => {
        setSelectedBrand(brand);
        let filtered = data.filter((item) => item.brand === brand);
        filtered = handleSort(sortOption, filtered);
        setFilterPrice(filtered);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        let filtered = data.filter((item) => item.category === category);
        filtered = handleSort(sortOption, filtered);
        setFilterPrice(filtered);
    };

    const handleToggleCategory = () => {
        setIsCategoryOpen(prev => !prev);
        $("#toggleCategory").slideToggle("slow");
    };

    const handleToggleBrand = () => {
        setIsBrandOpen(prev => !prev);
        $("#toggleBrand").slideToggle("slow");
    };

    const displayedData = filterPrice.length > 0 ? filterPrice : data;
 
    const sortedDisplayedData = [...displayedData].sort((a, b) => {
        switch (sortOption) {
            case 'Price--Low to High':
                return a.selling_price - b.selling_price;
            case 'Price--High to Low':
                return b.selling_price - a.selling_price;
            case 'Newest first':
                return new Date(b.createdAt) - new Date(a.createdAt);
            default:
                return 0;
        }
    });
 
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        let timer;
        if (!loading) {
            timer = setTimeout(() => {
                setShowLoader(false);
            }, 1200); 
        } else {
            setShowLoader(true); // Show loader while loading is true
        }

        return () => clearTimeout(timer); 
    }, [loading]);

    if (showLoader) {
        return (
            <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                <MainLoader />
            </div>
        );
    }
    
    const handleProductClick = (item) => {
        addRecentProduct(item);
        navigate(`/product/${item.SKU_id}`);
    };
 
    const handleClearFilters = () => {
        setRange(maxPrice);
        setFilterPrice(data);
        setSelectedCategory(null);
        setSelectedBrand('');
        setSortOption('');
        navigate("/productlist");
    };
 
    return (
        <>
        <Helmet>
  <title>Shop Home Decor - DigiUncle</title>
  <meta name="description" content="Browse DigiUncle's premium collection of handcrafted home decor, candle holders, wall art, and more." />
  <meta name="keywords" content="DigiUncle products, home decor collection, candle holders, premium quality decor" />
  <link rel="canonical" href="https://digiuncle.co.in/productlist" />
  <meta property="og:title" content="Shop Home Decor - DigiUncle" />
  <meta property="og:description" content="Discover a wide range of premium handcrafted home decor products at DigiUncle." />
  <meta property="og:url" content="https://digiuncle.co.in/productlist" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://digiuncle.co.in/image/splash.png" />
  <meta property="og:site_name" content="DigiUncle Creations"/>
</Helmet>

        <div className="h-full w-full px-6 pt-5 flex flex-col md:flex-row justify-between mb-20 md:mb-0">
            {/* Filters Section */}
            <div className="w-full h-fit bg-blue-100 md:w-1/4 mb-4 md:mb-0 text-black p-4 rounded-lg shadow-md overflow-y-auto" style={{ color: 'black'}}>
                <div>
                    <h2 className="text-xl font-medium py-2 font-sans px-1 flex justify-between">
                        <span>Filter</span>
                        <span>
                            <button
                                onClick={handleClearFilters}
                                className="border border-black rounded-full text-sm px-2 py-1 hover:bg-white hover:text-blue duration-500"
                            >
                                Clear
                            </button>
                        </span>
                    </h2>
                    <hr style={{ height: '1px', color: 'black' }} />
                </div>

                {/* Brand Filter Section */}
                <div>
                    <h2
                        className="text-lg font-medium text-gray-700 py-2 font-sans px-1 flex justify-between items-center cursor-pointer"
                        onClick={handleToggleBrand}>
                        <span>BRANDS</span>
                        <span id="arrow" className={isBrandOpen ? "rotate-[-180deg]" : "rotate-0"}>
                            <IoIosArrowDown />
                        </span>
                    </h2>
                    <hr />
                    <form className={`w-full h-20 overflow-y-auto py-3 ${isBrandOpen ? "" : "hidden"}`} id="toggleBrand">
                        {uniqueBrands.map((brand) => (
                            <div className="flex gap-3 px-3" key={brand} onClick={() => handleBrandClick(brand)}>
                                <input
                                    type="checkbox"
                                    id={brand}
                                    name="brand"
                                    value={brand}
                                    checked={selectedBrand === brand}
                                    onChange={() => handleBrandClick(brand)}
                                />
                                <label htmlFor={brand}>{brand}</label>
                            </div>
                        ))}
                    </form>
                </div>

                {/* Category Filter Section */}
                <div>
                    <h2
                        className="text-lg font-medium text-gray-700 py-2 font-sans px-1 flex justify-between items-center cursor-pointer"
                        onClick={handleToggleCategory}
                    >
                        <span>CATEGORIES</span>
                        <span id="arrow" className={isCategoryOpen ? "rotate-[-180deg]" : "rotate-0"}>
                            <IoIosArrowDown />
                        </span>
                    </h2>
                    <hr />
                    <form className={`w-full h-40 overflow-y-auto py-3 ${isCategoryOpen ? "" : "hidden"}`} id="toggleCategory">
                        {uniqueCategories.map((category) => (
                            <div className="flex gap-3 px-3" key={category} onClick={() => handleCategoryClick(category)}>
                                <input
                                    type="radio"
                                    id={category}
                                    name="category"
                                    value={category}
                                    checked={selectedCategory === category}
                                    onChange={() => handleCategoryClick(category)}
                                />
                                <label htmlFor={category}>{category}</label>
                            </div>
                        ))}
                    </form>
                </div>

                {/* Price and Sort Filters */}
                <div>
                    <h2 className="text-xl font-medium py-2 font-sans px-1 flex justify-between">Range</h2>
                    <input
                        type="range"
                        min={minPrice}
                        max={maxPrice}
                        value={range}
                        className="mx-0 ps-2"
                        onChange={(e) => handleChange(e.target.value)}
                    />
                    <p className=" flex w-full justify-between">
                        <span className="border border-gray-600 px-5">Min</span>
                        <button className="px-5 border-gray-600 border">₹{range}</button>
                    </p>
                    <hr />
                    <div className="py-2 px-3 gap-5 flex-wrap">
                        <span className="font-medium border border-black rounded-full text-sm px-2 py-1 hover:bg-white">Sort By</span><br /><br />
                        <button
                            type="button"
                            className={`shorted ${sortOption === 'Price--Low to High' ? 'selected' : ''}`}
                            onClick={() => setSortOption('Price--Low to High')}
                        >
                            Price--Low to High
                        </button><br /><br />
                        <button
                            type="button"
                            className={`shorted ${sortOption === 'Price--High to Low' ? 'selected' : ''}`}
                            onClick={() => setSortOption('Price--High to Low')}
                        >
                            Price--High to Low
                        </button><br /><br />
                        <button
                            type="button"
                            className={`shorted ${sortOption === 'Newest first' ? 'selected' : ''}`}
                            onClick={() => setSortOption('Newest first')}
                        >
                            Newest first
                        </button>
                    </div>
                </div>
                <hr/>
            </div>

            {/* Product Listing Section */}
            <div className="h-full w-full md:w-[78%] md:ml-3">
                <ol className="flex items-center bg-blue-100 px-5 whitespace-nowrap" aria-label="Breadcrumb" style={{ height: '30px', borderRadius: '20px' }}>
                    <li className="inline-flex items-center">
                        <Link to="/" className="flex items-center text-lg text-gray-900 hover:text-blue-600 focus:outline-none">
                            <b>Home</b>
                        </Link>
                        <IoIosArrowForward />
                    </li>
                    <li className="inline-flex items-center">
                        <p className="flex items-center text-lg text-gray-900 focus:outline-none">
                            <b>Product List</b>
                        </p>
                    </li>
                </ol>

                <hr className='mt-3' />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 py-8">
                    {sortedDisplayedData.length > 0 ? (
                        sortedDisplayedData.map((item) => (
                            <div
                                key={item._id}
                                className="border p-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 flex flex-col justify-between cursor-pointer"
                                onClick={() => { handleProductClick(item); scrollToTop(); }}
                            >
                                {/* Image Section */}
                                <div className="relative w-full h-48 mb-4">
                                    {item.image && item.image.length > 0 ? (
                                        <img
                                            src={item.image[0]}
                                            alt={item.name}
                                            className="w-full h-full object-contain rounded-md transition-all duration-300 transform hover:scale-105"
                                        />
                                     ):(
                                        <div className="flex items-center justify-center h-full text-gray-500">
                                            No Image Available
                                        </div>
                                     )}
                                    <button
                                        className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (isInWishlist(item.SKU_id)) {
                                                handleRemoveFromWishlist(item.SKU_id);
                                            } else {
                                                handleAddToWishlist(item);
                                            }
                                        }}
                                    >
                                        <FaHeart
                                            className={`text-gray-500 ${isInWishlist(item.SKU_id) ? 'text-red-500' : ''}`}
                                        />
                                    </button>
                                </div>

                                {/* Product Details */}
                                <div className="text-center p-1" style={{ backgroundColor: '#f8e9ca', borderRadius: '0 0 10px 10px', color: 'black' }}>
                                    <h3 className="sm:text-base font-semibold text-black truncate">{item.name}</h3>

                                    <div className="flex justify-center items-center space-x-2">
                                        <span className="text-sm sm:text-base font-semibold text-gray-700">
                                            ₹{item.selling_price}
                                        </span>
                                        {item.MRP_price && (
                                            <span className="text-xs sm:text-sm line-through text-red-500">
                                                ₹{item.MRP_price}
                                            </span>
                                        )}
                                        <span className="ml-2 text-green-600 text-sm">
                                            {((item.MRP_price - item.selling_price) / item.MRP_price * 100).toFixed(0)}% Off
                                        </span>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ReactStars
                                            count={5}
                                            value={calculateAverageRating(item.SKU_id)}
                                            edit={false}
                                            size={24}
                                            color2={'#ffd700'}
                                        />
                                        <span className="text-sm text-gray-600 ml-2">({reviews.filter(review => review.SKU_Id === item.SKU_id).length} reviews)</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-span-4 text-center text-xl text-gray-600">
                            No products found.
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>);
};

export default ProductListing;

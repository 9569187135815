import React, { useEffect, useState } from 'react';
import { getAllOrdersURL, updateOrderStatusURL, createNotificationURL } from '../api/api';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import {useAuthContext} from '../context';
import MainLoader from './MainLoader';
 
const AdminPanel = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [error, setError] = useState('');
  const [statusSuccess, setStatusSuccess] = useState(''); // Success message for status change
  const [statusError, setStatusError] = useState(''); // Error message for status change
  const [updatingOrderId, setUpdatingOrderId] = useState(null);
  const [showOrdersPopup, setShowOrdersPopup] = useState(false);
  const [notificationForm, setNotificationForm] = useState({
    notification_id: '',
    email: '',
    title: '',
    message: '',
    type: '',
  });
  const [notificationError, setNotificationError] = useState('');
  const [notificationSuccess, setNotificationSuccess] = useState('');
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
 
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const {user}= useAuthContext();
  const ad = user;
  useEffect(() => {
    if (!user) return; // Ensures user is available
    if (user.type !== 'admin') {
      toast.error('Unauthorized access');
      navigate('/');
    }
  }, [user, navigate]); 

  const allStatuses = [
    "Change Status",
    "Order Placed",
    "Shipping",
    "Out For Delivery",
    "Delivered",
    "Cancelled",
    "Unverified Payment",
    "Payment Successful - Verification Pending",
    "Return Requested",
    "Exchange Requested"
  ];
 
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(getAllOrdersURL, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(response.data);
        localStorage.setItem('adminOrders', JSON.stringify(response.data));
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Unauthorized access")
          console.log('Unauthorized access. Status code:', error.response.status);
      } else{
        setError('Error fetching orders: ' + error.message);
        setLoading(false);
      }}
    };
    fetchOrders();
  }, [token]);
 
  const generateUniqueId = (prefix = 'notif') => {
    return `${prefix}_${Date.now()}_${Math.floor(Math.random() * 100000)}`;
  };
 
 
  const handleStatusChange = async (orderId, newStatus, customerEmail) => {
    if (ad.type !== 'admin') {
      navigate('/');
    }
    if (newStatus === "Change Status") {
      setStatusError('Please select a valid status.');
      setLoading(false)
      return;
    }
    console.log("status", orderId, newStatus);
    setUpdatingOrderId(orderId);
    try {
      // Step 1: Update the order status
      const response = await axios.put(updateOrderStatusURL, {
        order_id: orderId,
        Status_details: {status:newStatus},
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
   
      if (response.status === 200) {
        // Step 2: Update the orders state in the frontend
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((order) =>
            order.order_id === orderId
          ?{ ...order, Status_details: { ...order.Status_details, status: newStatus } }
           : order
          );
          return updatedOrders;
        });
 
        // Step 3: Generate the notification details
        const notification = {
          notification_id: generateUniqueId('notif'),
          email: customerEmail,
          title: `Order Status Updated: ${newStatus}`,
          message: `Your order status is now: ${newStatus}`,
          type: 'Info',
        };
 
        // Step 4: Send the notification to the customer
        await axios.post(createNotificationURL, notification, {
          headers: { Authorization: `Bearer ${token}` },
        });
 
        // Step 5: Set success message
        setStatusSuccess('Order status updated successfully!');
        setStatusError(''); // Clear error message
      } else {
        setStatusError('Failed to update status: ' + response.data.message);
        setStatusSuccess(''); // Clear success message
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access");
        console.log('Unauthorized access. Status code:', error.response.status);
      } else {
        setStatusError('Error updating status: ' + error.message);
        setStatusSuccess(''); // Clear success message
      }
    } finally {
      setUpdatingOrderId(null);
    }
  };
 
 
  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNotificationForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
 
  const handleCreateNotification = async (e) => {
    // if(setShowNotificationPopup(true)){
    //   setNotificationSuccess('');
    // setNotificationError('');
    // }
    e.preventDefault();
    setReloading(true)
    if (ad.type !== 'admin') {
      navigate('/');
    }
 
    const emailToSend = notificationForm.email.trim() === '' ? null : notificationForm.email;
 
    try {
      const response = await axios.post(createNotificationURL, {
        ...notificationForm,
        notification_id: generateUniqueId('notif'), // Generate unique ID
        email: emailToSend,  // Send null if email is empty
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
           
      if (response.status === 201) {
        setNotificationSuccess('Notification created successfully!');
        setNotificationForm({
          notification_id: '',
          email: '',
          title: '',
          message: '',
          type: '',
        });
        toast.success('Notification Sent Successfully');
        setReloading(false)
        // setShowNotificationPopup(false); // Close popup on success
       
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access");
        setReloading(false)
        console.log('Unauthorized access. Status code:', error.response.status);
      } else {
        toast.error("Notification failed");
        setReloading(false)
        setNotificationError('Error creating notification: ' + (error.response?.data?.message || error.message));
      }
    }
  };
 
 
  const openNotificationPopup = () => setShowNotificationPopup(true);
  const closeNotificationPopup = () => {setShowNotificationPopup(false); setNotificationError(''); setNotificationSuccess('')};
  const openOrdersPopup = () => setShowOrdersPopup(true);
  const closeOrdersPopup = () => setShowOrdersPopup(false);
 
  // if (loading || !user) return <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
  //                                 <MainLoader />
  //                               </div>;
 
  const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        let timer;
        if (!loading || !user) {
            timer = setTimeout(() => {
                setShowLoader(false);
            }, 1200); 
        } else {
            setShowLoader(true); 
        }

        return () => clearTimeout(timer); 
    }, [loading]);

    if (showLoader) {
        return (
            <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                <MainLoader />
            </div>
        );
    }

  const inventory = async () => {
    navigate('/product-inventory')
  }
  const add = async () => {
    navigate('/product-management')
  }
  return (
    <div className="p-8 md:mb-0 mb-20">
      <h1 className="text-center text-3xl font-bold text-gray-800 mb-6">Admin Panel</h1>
 
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-8">
 
  {/* Card 1 - Orders */}
  <div className="w-full sm:w-64">
    <div className="bg-white shadow-lg p-6 rounded-md hover:shadow-xl transition-transform transform hover:scale-105 h-[300px] flex flex-col justify-between">
      <img
        src="material-management.png"
        alt="Orders"
        className="w-full h-40 object-contain rounded-t-md"
      />
      <div className="mt-4 text-center">
        <p className="text-gray-800 text-sm font-semibold">
          To check or change the Order Status.
        </p>
        <button
          onClick={openOrdersPopup}
          className="bg-green-500 text-white px-4 py-2 mt-3 rounded-md w-full hover:bg-green-600 transition-colors"
        >
          Orders Status
        </button>
      </div>
    </div>
  </div>
 
  {/* Card 2 - Notifications */}
  <div className="w-full sm:w-64">
    <div className="bg-white shadow-lg p-6 rounded-md hover:shadow-xl transition-transform transform hover:scale-105 h-[300px] flex flex-col justify-between">
      <img
        src="bell.png"
        alt="Notification"
        className="w-full h-40 object-contain rounded-t-md"
      />
      <div className="mt-4 text-center">
        <p className="text-gray-800 text-sm font-semibold">
          Create and send notifications to users.
        </p>
        <button
          onClick={openNotificationPopup}
          className="bg-green-500 text-white px-4 py-2 mt-3 rounded-md w-full hover:bg-green-600 transition-colors"
        >
          Create Notification
        </button>
      </div>
    </div>
  </div>
 
  {/* Card 3 - Inventory */}
  <div className="w-full sm:w-64">
    <div className="bg-white shadow-lg p-6 rounded-md hover:shadow-xl transition-transform transform hover:scale-105 h-[300px] flex flex-col justify-between">
      <img
        src="responsive.png"
        alt="Inventory"
        className="w-full h-40 object-contain rounded-t-md"
      />
      <div className="mt-4 text-center">
        <p className="text-gray-800 text-sm font-semibold">
          Check all product inventories, create and edit also.
        </p>
        <button
          onClick={inventory}
          className="bg-green-500 text-white px-4 py-2 mt-3 rounded-md w-full hover:bg-green-600 transition-colors"
        >
          Product Inventories
        </button>
      </div>
    </div>
  </div>
 
  {/* Card 4 - Add Product */}
  <div className="w-full sm:w-64">
    <div className="bg-white shadow-lg p-6 rounded-md hover:shadow-xl transition-transform transform hover:scale-105 h-[300px] flex flex-col justify-between">
      <img
        src="add.png"
        alt="Add Product"
        className="w-full h-40 object-contain rounded-t-md"
      />
      <div className="mt-4 text-center">
        <p className="text-gray-800 text-sm font-semibold">
          Add new or update products to your store.
        </p>
        <button
          onClick={add}
          className="bg-green-500 text-white px-4 py-2 mt-3 rounded-md w-full hover:bg-green-600 transition-colors"
        >
         Product Management
        </button>
      </div>
    </div>
  </div>
</div>
 
      {/* Orders Popup Modal */}
      {showOrdersPopup && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md w-full max-w-4xl overflow-x-auto relative">
      <button
        onClick={closeOrdersPopup}
        className="absolute top-4 right-4 text-red-500 hover:underline text-bold"
      >
        <IoCloseSharp size='30' />
      </button>
      <div className="flex flex-cols mb-4">
        <h2 className="text-xl font-semibold text-gray-800">Orders</h2>
      </div>
     
    {/* Success and Error messages */}
    {statusSuccess && <div className="text-green-500 mb-2">{statusSuccess}</div>}
    {statusError && <div className="text-red-500 mb-2">{statusError}</div>}
 
      <div className="overflow-x-auto bg-white shadow-lg rounded-lg mb-8 mt-4">
        <table className="min-w-full table-auto bg-gray-50 border-collapse">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-6 text-left text-sm font-medium">Order ID</th>
              <th className="py-3 px-6 text-left text-sm font-medium">Customer Name</th>
              <th className="py-3 px-6 text-left text-sm font-medium">Order Status</th>
              <th className="py-3 px-6 text-left text-sm font-medium">Product Details</th>
              <th className="py-3 px-10 text-left text-sm font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.order_id} className="border-b hover:bg-gray-100 transition-colors">
                <td className="py-4 px-6 text-sm">{order.order_id}</td>
                <td className="py-4 px-6 text-sm">{order.customer_details.firstName} {order.customer_details.lastName}</td>
                <td className="py-4 px-6 text-sm text-center">{order.Status_details.status}</td>
                <td className="py-4 px-6 text-sm">
                  {order.product_details?.length > 0 ? (
                    <ul>
                      {order.product_details.map((product) => (
                        <li key={product.product_id}>
                          {product.product_name} (Quantity: {product.quantity})
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No products available</p>
                  )}
                </td>
                <td className="py-4 px-6 text-sm text-center">
                  <select
                    onChange={(e) => handleStatusChange(order.order_id, e.target.value, order.customer_details.email)}
                    value={order.Status_details.status}
                    className="border rounded-md px-3 py-1"
                  >
                    {allStatuses.filter((status) => status !== order.Status_details.status).map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}
 
 
      {/* Notification Popup Modal */}
      {showNotificationPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md w-full max-w-lg overflow-x-auto relative">
            <button
              onClick={closeNotificationPopup}
              className="absolute top-4 right-4 text-red-500 hover:underline text-bold"
            >
              <IoCloseSharp size='30' />
            </button>
            <h2 className="text-xl font-semibold mb-4">Create a Notification</h2>
            <form onSubmit={handleCreateNotification}>
              {notificationError && <div className="text-red-500 mb-2">{notificationError}</div>}
              {notificationSuccess && <div className="text-green-500 mb-2">{notificationSuccess}</div>}
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email (Optional)</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.email}
                  onChange={handleNotificationChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.title}
                  onChange={handleNotificationChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.message}
                  onChange={handleNotificationChange}
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
                <select
                  id="type"
                  name="type"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.type}
                  onChange={handleNotificationChange}
                  required
                >
                  <option value="Info">Info</option>
                  <option value="Warning">Warning</option>
                  <option value="Error">Error</option>
                </select>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  {reloading?'loading':'Send Notification'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default AdminPanel;
 
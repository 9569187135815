import React from 'react'

const MainLoader = () => {

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-white fixed top-0 left-0 z-50">
      <div className="flex flex-col items-center justify-center space-y-2">
      <img src="/loader.gif" alt="Loading..." style={{ width: "600px", height: "auto" }} />
      </div>
    </div>
  )
  
}

export default MainLoader

import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context';
import OTPInput, { ResendOTP } from 'otp-input-react';

const ForgotPasswordOtp = ({ handleBackToLogin }) => {
  const { OTPset, verifyOtp } = useAuthContext();
  const [otp, setOtp] = useState('');
  const [isDisabled, setIsDisabled] = useState(true); // Disable resend button initially
  const [timer, setTimer] = useState(30); // Timer for resend OTP
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading]= useState(false);
  const Forgotemail = location?.state?.Forgotemail;
  const email = Forgotemail;
  const Forgotmobile = location?.state?.Forgotmobile;
  const mobile = Forgotmobile;
  const purpose = location?.state?.purpose;

  useEffect(() => {
    if (!Forgotemail && !Forgotmobile) {
      toast.error('Email or mobile is required');
      navigate('/login'); // Navigate back to login page if no email/mobile
    }
  }, [Forgotemail, Forgotmobile, navigate]);

  // Start a timer when the component mounts or timer resets
  useEffect(() => {
    if (isDisabled) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsDisabled(false); // Enable the button when timer ends
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval); // Cleanup timer on unmount
    }
  }, [isDisabled]);

  const handleGetOTP = async () => {
    try {
      if (!Forgotemail && !Forgotmobile) {
        toast.error('No email or mobile provided');
        return;
      }

      const result = await OTPset({ email, mobile });

      if (result) {
        toast.success('OTP sent');
        setTimer(30); // Reset the timer
        setIsDisabled(true); // Disable the button again
      }
    } catch (error) {
      console.error('Error in handleGetOTP:', error);
      toast.error('An error occurred while sending OTP');
    }
  };

  const handleVerifyOTP = async () => {
    setLoading(true);
    if (!otp) {
      toast.error('OTP is required');
      setLoading(false);
      return;
    }

    try {
      const result = await verifyOtp({ otp, email, mobile, purpose });
      if (result) {
        toast.success('Verification successful');
        setLoading(false);
        navigate('/reset-password', {
          state: { Forgotemail: Forgotemail, Forgotmobile: Forgotmobile },
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error in verifying OTP:', error);
      toast.error('Failed to verify OTP');
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <div className="bg-blue-50 shadow-lg rounded-lg p-6 w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
        <h3 className="text-3xl text-black font-semibold mb-6 text-center">
          Verify Your OTP
        </h3>
        <div className="text-lg text-gray-700 mb-4 text-center">
          Enter the OTP sent to your email or mobile
        </div>
        <div className="flex justify-center mb-4">
          <OTPInput
            className="otpnum"
            value={otp}
            OTPLength={6}
            onChange={(otp) => setOtp(otp)}
          />
        </div>
        <div className="flex justify-center mb-6">
          <ResendOTP
            maxTime={30} // User must wait 30 seconds before resending
            onResendClick={handleGetOTP}
            renderButton={({ disabled, onClick }) => (
              <button
                onClick={onClick}
                disabled={isDisabled} // Use custom state for enabling/disabling
                className={`text-blue-500 border border-blue-700 rounded px-6 py-3 font-semibold ${
                  isDisabled
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer hover:bg-blue-500 hover:text-white transition-all'
                }`}
              >
                Resend OTP {isDisabled && `(${timer}s)`}
              </button>
            )}
            renderTime={() => null} // Render nothing for the timer
          />
        </div>

        <div className="text-center">
          <button
            className="bg-blue-500 text-white rounded px-8 py-3 text-xl font-semibold w-full hover:bg-blue-600 transition-all"
            onClick={handleVerifyOTP}
          >
            {loading?'Loading....':'Verify OTP'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordOtp;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createInventoryURL, deleteInventoryURL, getAllInventoriesURL, updateInventoryURL } from '../api/api';
import { IoCloseSharp } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context';
import MainLoader from './MainLoader';
 
const Inventory = () => {
  const [inventories, setInventories] = useState([]);
  const [filteredInventories, setFilteredInventories] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const {user}= useAuthContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [reloading, setReloading] = useState(false);
 
  const [formData, setFormData] = useState({
    product_name: '',
    sku_id: '',
    in_stock: '',
    out_of_stock: '',
    remaining_stock: '',
    offline_sell: '',
    online_sell: '',
    remarks: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
 
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);  // New state for delete confirmation
  const [inventoryToDelete, setInventoryToDelete] = useState(null); // State to store the inventory item to delete
 
  useEffect(() => {
    if (!user) return; // Ensures user is available
    if (user.type !== 'admin') {
      toast.error('Unauthorized access');
      navigate('/');
    }
  }, [user, navigate]); 

  useEffect(() => {
    getAllInventories();
    setLoading(false);
  }, []);
  
  useEffect(() => {
    // Filter inventories based on the search term
    const result = inventories.filter((inventory) =>
      inventory.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredInventories(result);
  }, [searchTerm, inventories]);
 
  const getAllInventories = async () => {
    try {
      const response = await axios.get(getAllInventoriesURL,
      {
        headers: { Authorization: `Bearer ${token}` },
      });
      setInventories(response.data);
      setFilteredInventories(response.data); // Initialize filtered inventories
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access");
        console.log('Unauthorized access. Status code:', error.response.status);
    }
    else{
      toast.error("Error fetching inventories");
      console.error('Error fetching inventories:', error.message);
    }
    }
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
 
  const apiCall = async (method, url, data = {}, config = {}) => {
    try {
      const headers = { ...config.headers };  // Ensure headers are passed
      const response = await axios({
        method,
        url,
        data: method === 'get' ? null : data, // Use data only for non-GET requests
        headers,  // Pass headers if any
      });
      await getAllInventories(); // Refresh the inventory list after each action
      return response.data; // Return the data from the response
    } catch (error) {
      // Log error details
      console.error(`${method.toUpperCase()} request failed:`, error.message);
      // Return the error message or response to the caller
      return { error: error.message };
    }
  };
 
 
  const createInventory = async () => {
    const { product_name, sku_id, in_stock, out_of_stock, remaining_stock, offline_sell, online_sell } = formData;
    if (!product_name || !sku_id || !in_stock || !out_of_stock || !remaining_stock || !offline_sell || !online_sell ) {
      toast.error("Please fill all the required fields");
      return;
    }
 
    const inventoryExists = inventories.some((inventory) => inventory.sku_id === sku_id && inventory.product_name===product_name);
    if (inventoryExists) {
    toast.error("Inventory with this SKU ID and Name already exists.");
    return;
  }
 
    setReloading(true);
    try {
    await apiCall('post', createInventoryURL, formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success('Inventory created Successfully');
    setReloading(false);
    closeModal();
  } catch (error) {
    if (error.response && error.response.status === 401) {
      toast.error("Unauthorized access")
      setReloading(false);
      console.log('Unauthorized access. Status code:', error.response.status);
  }
  else{
    toast.error("Error creating inventories")
    setReloading(false);
    console.error('Error creating inventories:', error.message);
  }
  }
  };
 
  const updateInventory = async () => {
    setReloading(true);
    try {
      console.log("Token being sent:", token); // Log the token to check if it's correct
      await apiCall('put', updateInventoryURL, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Inventory updated Successfully');
      setReloading(false);
      closeEditModal();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setReloading(false);
        console.log('Unauthorized access. Status code:', error.response.status);
      } else {
        toast.error("Error updating inventories");
        setReloading(false);
        console.error('Error updating inventories:', error.message);
      }
    }
  };
 
 
  const openModal = () => {
    resetFormData();
    setIsModalOpen(true);
  };
 
  const closeModal = () => {
    resetFormData();
    setIsModalOpen(false);
  };
 
  const openEditModal = (inventory) => {
    setFormData(inventory);
    setIsEditModalOpen(true);
  };
 
  const closeEditModal = () => {
    resetFormData();
    setIsEditModalOpen(false);
  };
 
  const resetFormData = () => {
    setFormData({
      product_name: '',
      sku_id: '',
      in_stock: '',
      out_of_stock: '',
      remaining_stock: '',
      offline_sell: '',
      online_sell: '',
      remarks: '',
    });
  };
 
  const deleteInventory = async (sku_id) => {
    setIsDisabled(true);
    try {
      await apiCall('delete', deleteInventoryURL, { sku_id }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Inventory deleted Successfully');
      setIsDeleteModalOpen(false);  // Close the modal after deletion
      setInventoryToDelete(null);  // Reset the inventory to delete
      setIsDisabled(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access");
        setIsDisabled(false);
        console.log('Unauthorized access. Status code:', error.response.status);
      } else {
        toast.error("Error deleting inventories");
        setIsDisabled(false);
        console.error('Error deleting inventories:', error.message);
      }
    }
  };
 
  const handleDeleteConfirmation = (inventory) => {
    setInventoryToDelete(inventory);  // Set the inventory item to be deleted
    setIsDeleteModalOpen(true);  // Open the delete confirmation modal
  };
 
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);  // Close the modal
    setInventoryToDelete(null);  // Reset the inventory item
  };
  const [isDisabled, setIsDisabled] = useState(false);
  // Render the delete confirmation modal
  const renderDeleteConfirmationModal = () => (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-75 z-50">
      <div className="relative bg-white p-4 rounded-xl shadow-xl w-11/12 sm:w-3/4 md:w-1/3 lg:w-1/4">
        <h2 className="text-lg sm:text-xl font-semibold mb-4 text-center">
          Are you sure you want to delete this inventory?
        </h2>
        <div className="flex justify-center gap-3">
          <button
            onClick={() => deleteInventory(inventoryToDelete.sku_id)}
            className="bg-red-500 text-white px-3 py-2 text-sm rounded shadow-md hover:bg-red-600"
            disabled={isDisabled}style={{
              filter: isDisabled ? 'blur(1px)' : 'none',
              cursor: isDisabled ? 'not-allowed' : 'pointer'  
            }}
          >
            {isDisabled ? 'Deleted' : 'Yes, Delete'}
          </button>
          <button
            onClick={closeDeleteModal}
            className="bg-gray-500 text-white px-3 py-2 text-sm rounded shadow-md hover:bg-gray-600"
          >
            No, Cancel
          </button>
        </div>
      </div>
    </div>
  );
 
 
  const renderInventoryForm = (isEditMode) => (
    <form className="space-y-4">
      {Object.keys(formData).map((field, index) => (
        <div key={index} className="space-y-1">
          <label htmlFor={field} className="text-sm font-medium capitalize">{field.replace(/_/g, ' ')}</label>
          {field === 'remarks' ? (
            <textarea
              name={field}
              id={field}
              placeholder={`Enter ${field.replace(/_/g, ' ')}`}
              onChange={handleInputChange}
              value={formData[field]}
              className="w-full p-2 border rounded-lg shadow-sm text-sm"
            />
          ) : (
            <input
              type="text"
              name={field}
              id={field}
              placeholder={`Enter ${field.replace(/_/g, ' ')}`}
              onChange={handleInputChange}
              value={formData[field]}
              className="w-full p-2 border rounded-lg shadow-sm text-sm"
            />
          )}
        </div>
      ))}
       <button type="submit"
        onClick={(e) => {
       e.preventDefault();
       if (!reloading) {
          isEditMode ? updateInventory() : createInventory();
        }
       }}className={`w-full p-3 rounded-lg shadow-md ${
        reloading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
       } text-white`}
       disabled={reloading}>
 
     {reloading ? 'Loading...' : isEditMode ? 'Update Inventory' : 'Create Inventory'}
    </button>
    </form>
  );
 
  // if (loading || !user) {
  //   return <div className="flex justify-center items-center h-screen">Loading...</div>;
  // }
 
  const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        let timer;
        if (!loading || !user) {
            timer = setTimeout(() => {
                setShowLoader(false);
            }, 1200); // Ensure the loader is visible for at least 2 seconds
        } else {
            setShowLoader(true); // Show loader while loading is true
        }

        return () => clearTimeout(timer); // Cleanup timeout when component unmounts
    }, [loading,user]);

    if (showLoader) {
        return (
            <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                <MainLoader />
            </div>
        );
    }
    
  if (filteredInventories.length===0)  { return <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                              <MainLoader />
                            </div> 
                            }

  return (
    <div className="container mx-auto p-4 md:mb-0 mb-20">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 pb-2 tracking-wide">
          Inventory Management
        </h1>
        <button
          onClick={openModal}
          className="w-full sm:w-auto p-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 font-semibold"
        >
          Create Inventory
        </button>
      </div>
 
      {/* Search input */}
      <div className="mt-4 mb-6">
        <input
          type="text"
          placeholder="Search for product"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 border rounded-lg shadow-sm text-sm"
        />
      </div>
 
      {isEditModalOpen && (
  <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-75 z-50">
    <div className="relative bg-white p-4 rounded-xl shadow-xl w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto">
      <button
        onClick={closeEditModal}
        className="absolute top-4 right-4 text-red-500 hover:text-red-700"
      >
        <IoCloseSharp size="24" />
      </button>
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center">Edit Inventory</h2>
      {renderInventoryForm(true)}
    </div>
  </div>
)}
 
{isModalOpen && (
  <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-75 z-50">
    <div className="relative bg-white p-4 rounded-xl shadow-xl w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto">
      <button
        onClick={closeModal}
        className="absolute top-4 right-4 text-red-500 hover:text-red-700"
      >
        <IoCloseSharp size="24" />
      </button>
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center">Create Inventory</h2>
      {renderInventoryForm(false)}
    </div>
  </div>
)}
 
 
      {/* Confirmation Modal */}
      {isDeleteModalOpen && renderDeleteConfirmationModal()}
 
      <div className="mt-5">
        <h1 className="text-xl sm:text-2xl font-bold mb-3 underline text-center text-gray-800 tracking-wide">
          Product Inventory List
        </h1>
 
        {/* Scrollable container */}
        <div className="overflow-x-auto max-h-[400px] border border-gray-300 rounded-lg shadow-md">
          <table className="w-full border-collapse border border-gray-300 text-sm">
            <thead>
              <tr className="bg-blue-200 text-gray-700 font-bold">
                <th className="py-3 px-5 border border-gray-300 text-left">S-No.</th>
                <th className="py-3 px-5 border border-gray-300 text-left">Product Name</th>
                <th className="py-3 px-5 border border-gray-300 text-left">SKU ID</th>
                <th className="py-3 px-5 border border-gray-300 text-center">In Stock</th>
                <th className="py-3 px-5 border border-gray-300 text-center">Out of Stock</th>
                <th className="py-3 px-5 border border-gray-300 text-center">Remaining Stock</th>
                <th className="py-3 px-5 border border-gray-300 text-center">Offline Sell</th>
                <th className="py-3 px-5 border border-gray-300 text-center">Online Sell</th>
                <th className="py-3 px-5 border border-gray-300 text-left">Remarks</th>
                <th className="py-3 px-5 border border-gray-300 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredInventories.length > 0 ? (
                filteredInventories.map((inventory, index) => (
                  <tr
                    key={inventory.sku_id}
                    className={`${
                      index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                    } hover:bg-gray-100 transition duration-300`}
                  >
                    <td className="py-3 px-5 border border-gray-300 text-center">
                      {index + 1}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-left">
                      {inventory.product_name}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-left">
                      {inventory.sku_id}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-center">
                      {inventory.in_stock}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-center">
                      {inventory.out_of_stock}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-center">
                      {inventory.remaining_stock}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-center">
                      {inventory.offline_sell}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-center">
                      {inventory.online_sell}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-left">
                      {inventory.remarks}
                    </td>
                    <td className="py-3 px-5 border border-gray-300 text-center space-x-2">
                      <button
                        onClick={() => openEditModal(inventory)}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-yellow-600"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteConfirmation(inventory)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="py-3 text-center">
                    No inventories found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
 
export default Inventory;
 
 
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { 
  AuthContextProvider, 
  CartContextProvider, 
  GetContextProvider, 
  ProductContextProvider, 
  WishlistProvider, 
  ReviewContextProvider, 
  NotificationContextProvider 
} from './context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <AuthContextProvider>
        <WishlistProvider>
          <CartContextProvider>
            <ProductContextProvider>
              <ReviewContextProvider> 
                <BrowserRouter>
                  <NotificationContextProvider>
                    <GetContextProvider>
                      <App />
                    </GetContextProvider>
                  </NotificationContextProvider>
                </BrowserRouter>
              </ReviewContextProvider>
            </ProductContextProvider>
          </CartContextProvider>
        </WishlistProvider>
      </AuthContextProvider>
);
